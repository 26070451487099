body {
  overflow-x: hidden !important;
  font-family: "Open Sans", sans-serif;
}

.loading-cover {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: #fff;
  top: 0;
  left: 0;
}

.ngofullname {
  display: inline-block;
  vertical-align: text-bottom;
  max-width: 490px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.slick-initialized .slick-slide {
  overflow-x: hidden !important;
}

.NgoProfilePage-sideHeight {
  height: 1300px;
}

/* @media only screen and (-webkit-min-device-pixel-ratio: 1) {
  ::i-block-chrome,
  .ios-input {
      background: url(http://157.245.97.63:3001/static/media/aidbees-logo1.fc2e8a18.png);
      background-repeat: no-repeat;
      background-position: left;
  }
} */

.ReactCrop img {
  display: inline-block !important;
  vertical-align: top;
  display: flex;
}

.modal {
  overflow: auto;
}

.share-box-payment svg {
  overflow: visible !important;
}

.social_share_box svg {
  width: 49px;
  height: 44px;
  overflow: visible;
  margin-right: 1px;
}

.share-box-payment .fa-envelope,
.social_share_box .fa-envelope {
  width: 45px;
  line-height: 44px;
  background: #aaa;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 22px;
  margin-left: 5px;
}

svg :not(svg) {
  transform: translate(8px, -7px) !important;
}

.new-line {
  white-space: pre-line;
}

.mob-search {
  display: none;
  width: 130px;
  font-size: 12px !important;
  height: calc(1.2em + 0.75rem + 2px);
  /* background: url(../images/search-icon.png) !important; */
  background-repeat: no-repeat !important;
  background-position: right !important;
  background-color: #e5e5e5 !important;
}

/* html{
  overflow-x: hidden;
} */
@font-face {
  font-family: "Century Gothic";
  src: url("../fonts/CenturyGothic.eot");
  src: url("../fonts/CenturyGothic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/CenturyGothic.woff2") format("woff2"),
    url("../fonts/CenturyGothic.woff") format("woff"),
    url("../fonts/CenturyGothic.ttf") format("truetype"),
    url("../fonts/CenturyGothic.svg#CenturyGothic") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

input:focus,
select:focus {
  outline: 0 !important;
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}

svg {
  /* overflow: hidden;
  vertical-align: middle; */
  /* width: 100px;
  height: 80px; */
  /* transform: scale(.9); */
}

.side-menu .imghexa {
  width: 51px;
  height: auto;
  margin: 5px 5px 5px 0;
}

.imghexa {
  /* clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%); */
  /* transform: scale(.8); */
  clip-path: circle(50%);
}

.imghexa-rightSmall {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  width: 70px;
  height: 80px;
  margin-right: 8px;
}

.imghexa-big {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  transform: scale(1.2);
}

/* .slick-cloned{
 height: 0px!important;
 opacity:0!important;
} */
.big-height {
  padding-bottom: 150px;
  /* height: 3000px; */
}

.usernameModal .modal-dialog {
  max-width: 709px;
}

.usernameModal .profile-thumb {
  position: absolute;
  top: 0;
  left: 20px;
}

.usernameModal h3 {
  margin-bottom: 0;
  margin-top: -16px !important;
}

.top-cover {
  margin-top: 50px;
}

.container {
  height: auto;
}

.clear-both {
  clear: both;
  margin: auto;
  height: auto;
}

.btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.btn-primary {
  background: #f05368;
  color: #fff !important;
  font-size: 14px;
  font-weight: 700;
  padding: 6px 30px;
  border: 2px solid #f05368 !important;
  border-radius: 7px;
}

.btn-primary:hover {
  background: #fff;
  color: #f05368 !important;
}

.btn-primary:focus {
  background: #f05368;
  color: #fff !important;
}

.btn-primary-outline {
  background: #fff;
  color: #f05368 !important;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 60px;
  border: 2px solid #f05368;
  border-radius: 7px;
}

.btn-primary-outline:hover {
  background: #f05368;
  color: #fff !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #f05368 !important;
  border-color: #f05368 !important;
}

.btn-secondary {
  background: #bebebe;
  color: #fff !important;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0;
  border: 2px solid #bebebe !important;
  border-radius: 7px;
  width: 120px;
  margin-left: 3px;
}

.btn-secondary:hover {
  background: #fff;
  color: #f05368 !important;
}

.btn-primary.small {
  font-size: 12px;
  padding: 4px 20px;
  margin-top: 15px;
}

.btn-primary-outline.small {
  font-size: 12px;
  padding: 4px 18px !important;
}

.btn-primary-outline.small-green {
  background: #fff;
  color: #aece41 !important;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 18px !important;
  border: 2px solid #aece41;
  border-radius: 7px;
}

.btn-primary-outline.small-green:hover {
  background: #aece41;
  color: #fff !important;
}

.btn-primary-green {
  background: #aece41;
  color: #fff !important;
  font-size: 13px;
  font-weight: 600;
  /* padding: 8px 0; */
  border: 2px solid #aece41 !important;
  border-radius: 4px;
  width: 120px;
  /* margin-left: 3px; */
}

button.btn.btn-primary.mr-3.color-g:hover {
  color: #aece41 !important;
}

i.fa.fa-star-o:hover {
  color: black;
}

.btn-primary-green:hover {
  background: #fff;
  color: #aece41 !important;
  /* border:1px solid  #aece41; */
}

.btn-primary-blue {
  background: #5090c9;
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 0;
  border: 2px solid #5090c9 !important;
  border-radius: 7px;
  width: 120px;
}

.btn-primary-blue:hover {
  background: #fff;
  color: #5090c9 !important;
}

.btn-primary-purplle {
  background: #ae5ea2;
  color: #fff !important;
  font-size: 13px;
  font-weight: 600;
  /* padding: 8px 0; */
  /* padding: 2px 18px; */
  border: 2px solid #ae5ea2 !important;
  border-radius: 4px;
  width: 120px;
}

.btn-primary-purplle:hover {
  background: #fff;
  color: #ae5ea2 !important;
}

.btn-primary-blue2 {
  background: #70bbe8;
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  border: 2px solid #70bbe8 !important;
  border-radius: 7px;
}

.btn-primary-blue2:hover {
  background: #fff;
  color: #70bbe8 !important;
}

.btn-yellow {
  background: rgb(243, 227, 123);
  font-size: 14px;
  font-weight: 600;
  border-radius: 7px;
  color: rgb(105, 105, 105) !important;
  padding: 6px 0 !important;
  border: 2px solid rgb(243, 227, 123) !important;
  width: 113px;
}

.btn-yellow:hover {
  background: #fff;
  color: #696969 !important;
  border: 2px solid #696969 !important;
}

.btn-secondary-gray {
  background: #bebebe;
  color: #fff !important;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 12px;
  border: 2px solid #bebebe !important;
  border-radius: 7px;
}

.btn-secondary-gray:hover {
  background: #fff;
  color: #bebebe !important;
  border: 2px solid #bebebe !important;
}

.don-receive {
  width: 100% !important;
}

.small-height {
  min-height: 1100px;
}

.right-btn-open {
  display: none;
}

.right-btn-close {
  display: none;
}

.font-400 {
  font-weight: 400;
}

.w-mxc {
  width: max-content;
}

.medium-btn {
  padding: 6px 25px;
}

.rounded-50 {
  border-radius: 50%;
}

.rounded-50 {
  border-radius: 50%;
}

.text-primary {
  color: #f05368 !important;
}

.text-blue {
  color: #70bbe8 !important;
}

.text-livelihood {
  color: #1aafbd !important;
}

.text-disability {
  color: #aece41 !important;
}

.text-environment {
  color: #aece41;
}

.text-dark {
  color: #484848 !important;
}

.text-purplle {
  color: #ae5ea2 !important;
}

.text-green {
  color: rgb(174, 206, 65) !important;
}

.text-elderly {
  color: #dce575;
}

.text-children {
  color: #f3e37b;
}

.text-women {
  color: #f6a4b1;
}

.hr-women {
  border-top: 1px solid#F6A4B1;
}

.text-community {
  color: #73ccd5;
}

.text-human {
  color: rgb(80, 144, 201);
}

.hr-community {
  border-top: 1px solid#73CCD5;
}

.text-secondary {
  color: #434343 !important;
}

.text-blue {
  color: #5090c9 !important;
}

.text-lightgray {
  color: #696969 !important;
}

.text-gray {
  color: #696969 !important;
}

.text-shop {
  color: #19b0b9 !important;
}

.small-green {
  margin-top: 10px;
  padding: 6px 22px;
  font-size: 11px;
}

.small-purplle {
  margin-top: 10px;
  padding: 6px 0;
  font-size: 11px;
  width: 100px;
}

.hr-green {
  border-top: 1px solid rgb(174, 206, 65);
}

.hr-red {
  border-top: 1px solid rgb(240, 83, 104);
}

.hr-children {
  border-top: 1px solid#F3E37B;
}

.no-wrap {
  white-space: nowrap;
}

.hr-blue {
  border-top: 1px solid #5090c9;
}

.hr-purplle {
  border-top: 1px solid #ae5ea2;
}

.transform180 {
  transform: rotate(180deg);
}

.fa-heart {
  font-size: 18px;
  vertical-align: bottom;
  color: #bebebe;
  cursor: pointer;
}

/*.postModalSlider img{
 width:100%
}*/
.timelineSlider .slick-slide {
  margin: 0 0;
}

.timelineSlider .slick-slide img {
  width: 100%;
  height: 215px;
  object-fit: contain;
}

.postModalSlider .slick-slide {
  margin: 0 0;
}

.postModalSlider .slick-slide img {
  width: 100%;
  height: 492px;
  object-fit: contain;
}

.postModalSlider .slick-slide .modalVideo {
  height: 420px !important;
  width: 100%;
}

.postModalSlider .slick-prev:before,
.postModalSlider .slick-next:before {
  font-size: 26px !important;
  opacity: 1 !important;
  color: #f05368 !important;
}

.postModalSlider .slick-prev,
.postModalSlider .slick-next {
  z-index: 5;
}

.postModalSlider .slick-prev {
  left: -60px;
}

.postModalSlider .slick-next {
  right: -60px;
}

.highlight-icon {
  color: #f05368;
}

.like-share-row .dropdown {
  position: relative !important;
  display: inline-block;
  right: 0 !important;
}

.fa-heart.big-fa {
  vertical-align: middle !important;
  margin-right: 5px;
}

.green-bullets .slick-dots li.slick-active button:before {
  opacity: 1;
  color: rgb(174, 206, 65);
}

.purplle-bullets .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #ae5ea2;
}

.blue-bullets .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #5090c9;
}

.red-bullets .slick-dots li.slick-active button:before {
  opacity: 1;
  color: rgb(240, 83, 104) !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.5 !important;
  color: #aaa !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev:focus,
.slick-next:focus {
  outline: 0 !important;
}

.breadcrumbs {
  position: fixed;
  /* margin-top: 11px; */
  margin-top: 5px;
  top: 0px;
  /* z-index: 5; */
  z-index: 0;
  font-size: 24px;
  color: rgb(240, 83, 104);
  /* max-width: 682px;*/
  width: 90%; 
  background: rgb(255, 255, 255);
  /* border-right: 1px solid #dee2e6; */
  /* padding: 14px 0px 11px 15px; */
  /* padding-top: 10px; */
  /* margin-left: -15px; */
  padding-top: 19px;
  margin-left: 0px;
  max-width: 1010px;

}

.org-margin-left-0 {
  margin-left: -2px !important;
}

.aadar-text {
  color: #212529 !important;
}

.notification-counter {
  background-color: rgb(240, 83, 104);
  color: #fff;
  padding: 3px 5px;
  font-size: 9px;
  border-radius: 3px;
  margin-left: -10px;
  top: -10px;
}

.breadcrumbs img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
}

.breadcrumbs h3 {
  float: left;
  margin: 0;
}

.breadcrumbs .right-text {
  float: right;
  padding-right: 10px;
}

.breadcrumbs .arrow-mob-btn,
.breadcrumbs .arrow-mob-btn2,
.breadcrumbs .filter-mob-btn {
  display: none;
}

.donate-fix {
  position: absolute;
  top: 9px;
  right: 9px;
  display: none;
}

.container {
  max-width: 1366px;
}

h3 {
  color: #434343;
  font-size: 20px;
  font-weight: 700;
}

p {
  color: #434343;
}

.size12 {
  font-size: 12px !important;
}

.size13 {
  font-size: 13px !important;
}

.size14 {
  font-size: 14px !important;
}

.size15 {
  font-size: 15px !important;
}

.size16 {
  font-size: 16px !important;
}

.size18 {
  font-size: 18px !important;
}

.size20 {
  font-size: 20px !important;
}

.size22 {
  font-size: 22px !important;
}

.size24 {
  font-size: 24px !important;
}

.size30 {
  font-size: 30px !important;
}

.size36 {
  font-size: 36px !important;
}

.bold {
  font-weight: 700 !important;
}

.semiBold {
  font-weight: 600;
}

.h-100vh {
  height: 100vh;
}

.lightGray {
  color: #bebebe;
  font-size: 14px;
}

.lightGray2 {
  color: #898989;
}

.dark-text {
  color: #434343;
}

.italic {
  font-style: italic;
}

.light {
  font-weight: 300;
}

.bg-gray {
  background: #e5e6e6;
}

.overlay-cover {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  /* height: 100vh; */
  height:-webkit-fill-available;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  display: none;
}

.side-menu {
  position: fixed;
  /* width: 327px; */
  width: 230 !important;
  transition: all 0.5s;
  top: 0;
}

.side-menu-scroll-ngo {
  bottom: 0px;
}

.side-menu .before-login {
  margin-top: 140px;
}

.side-menu .side-logo {
  border-bottom: 1px solid rgb(229, 229, 229);
  padding: 15px 0 15px 0;
}

.side-menu .side-logo img {
  width: 15%;
}

img.img-fluid.text {
  width: 91%;
  padding-left: 8px;
}

.side-menu .menuCloseBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
}

/* .side-menu .userbox .imghexa {
 margin-left: -12px;
} */
.side-menu .userbox img {
  display: inline-block;
  vertical-align: middle;
}

.side-menu .userbox h3 {
  line-height: 18px;
  display: inline-block;
  vertical-align: middle;
}

.side-menu .userbox h3 span {
  font-size: 16px;
  font-style: italic;
  color: #bebebe;
  font-weight: 400;
}

.side-menu .userbox p {
  font-weight: 700;
}

.side-menu .userbox p span {
  font-weight: 400;
}

.side-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.side-menu ul li {
  border-bottom: 1px solid #e5e5e5;
  padding: 8px 5px;
}

.side-menu ul li a {
  font-size: 18px;
  color: #434343;
}

.side-menu ul li a:hover {
  color: #f05368;
  text-decoration: none;
}

.side-menu ul li a.active {
  color: #f05368;
}

.side-menu ul li a img {
  color: #434343;
  margin-right: 15px;
  display: inline-block;
  vertical-align: sub;
}

.side-menu .setting-box a {
  /* margin: 0 6px; */
}

.side-menu .footer-small {
  /* position: fixed; */
  width: auto;
  display: flow-root;
  bottom: 80px;
  margin-bottom: 90px;
}

.yt {
  width: 20px;
  display: inline-block;
  vertical-align: sub;
  /* margin-left: 10px; */
}

.side-menu .footer-small.loginpage {
  bottom: 80px;
}

.side-menu .footer-small.loginpage p,
.side-menu .footer-small.loginpage a {
  color: #434343;
  font-size: 13px;
}

.side-menu .footer-small a {
  margin-right: 9px;
  font-size: 12px;
}

.side-menu .footer-small a:hover {
  text-decoration: none;
}

.side-menu .plus {
  width: 20px;
  line-height: 20px;
  background-color: rgb(240, 83, 104);
  border-radius: 50%;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 17px;
  font-weight: 600;
  margin-right: 5px;
  display: inline-block;
}

.side-menu ul li a.more {
  font-size: 14px;
  display: table;
  margin: auto;
}

.side-menu .more-menu-open {
  display: none;
}

#signModal label {
  font-size: 15px;
  color: #333333;
}

#signModal label a {
  color: #333333;
  font-size: 15px;
}

/* .side-menu ul li a.more:hover .fa {
background-color: #ccc;
} */
.side-menu .footerSmalllogin {
  bottom: 100px;
}

.side-menu .footer-small p {
  display: block;
}

.side-menu .footer-small .fa-facebook {
  color: #3460a3;
  font-size: 20px;
}

.side-menu .footer-small .fa-twitter {
  color: #059ef5;
  font-size: 20px;
}

.side-menu .footer-small .fa-instagram {
  color: #e7247d;
  font-size: 20px;
  /* background-image: url(); */
}

/* about-side-menu start */
.about-side-menu {
  position: fixed;
  width: auto;
  transition: all 0.5s;
  top: 111px;
  padding-left: 10px;
  padding-right: 0px;
  border-right: 1px solid #e5e5e5;
  height: 100%;
  z-index: 10;
  background: #fff;
  margin-left: -7px;
}

.about-side-menu .aboutMenuCloseBtn {
  float: right;
  margin: 10px 15px 0 0;
}

.about-side-menu .side-logo img {
  width: 94%;
}

.about-side-menu .menuCloseBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
}

.about-side-menu .userbox img {
  display: inline-block;
  vertical-align: middle;
}

.about-side-menu .userbox h3 {
  line-height: 18px;
  display: inline-block;
  vertical-align: middle;
}

.about-side-menu .userbox h3 span {
  font-size: 16px;
  font-style: italic;
  color: #bebebe;
  font-weight: 400;
}

.about-side-menu .userbox p {
  font-weight: 700;
}

.about-side-menu .userbox p span {
  font-weight: 400;
}

.about-side-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.about-side-menu ul li {
  border-bottom: 0px solid #e5e5e5;
  padding: 8px 0;
}

.about-side-menu ul li span {
  display: none;
}

.about-side-menu ul li a {
  font-size: 18px;
  color: #434343;
}

.about-side-menu ul li a:hover {
  color: #f05368;
  text-decoration: none;
}

.about-side-menu ul li a.active {
  color: #f05368;
}

.about-side-menu ul li a img {
  color: #434343;
  /* margin-right: 15px; */
  display: inline-block;
  vertical-align: sub;
}

.about-side-menu .footer-small {
  position: fixed;
  width: auto;
  display: flow-root;
  bottom: 0;
}

.about-side-menu .footer-small a {
  color: #696969;
  font-size: 14px;
}

.about-side-menu .footer-small p {
  color: #696969;
  font-size: 14px;
}

.about-side-menu .dropdown-menu {
  top: -3px !important;
  left: 127px !important;
}

.about-side-menu .dropdown-menu .dropdown-item {
  padding: 0.3rem 1rem !important;
  font-size: 16px;
}

.side-menu .footer-small.loginpage {
  bottom: 80px;
}

.about-side-menu .footerSmalllogin {
  bottom: 100px;
}

.about-side-menu .footer-small p {
  display: block;
}

.about-side-menu .footer-small .fa-facebook {
  color: #3460a3;
  font-size: 20px;
}

.about-side-menu .footer-small .fa-twitter {
  color: #059ef5;
  font-size: 20px;
}

.about-side-menu .footer-small .fa {
  margin: 0 5px;
}

.about-side-menu .footer-small a:hover {
  text-decoration: none;
}

.about-side-menu .footer-small a {
  font-size: 12px;
  margin-right: 5px;
}

.about-side-menu .footer-small .fa-instagram {
  color: #e7247d;
  font-size: 20px;
  /* background-image: url(); */
}

.about-side-menu .bottom-icons {
  position: fixed;
  bottom: 20px;
}

/* about-side-menu end */
.about-page h3 {
  font-size: 50px;
  color: #4f4f4f;
  font-family: "Century Gothic";
  font-weight: normal;
}

.about-page h3 span {
  font-size: 35px;
  display: block;
}

.about-page p {
  line-height: 26px;
}

.about-page-inner p {
  line-height: 30px;
}

.about-page-inner ul {
  padding: 0 0 0 0;
}

.about-page-inner li {
  /* background-image: url(../images/bullet2.png); */
  background-repeat: no-repeat;
  padding: 0px 0px 10px 19px;
  list-style: none;
  background-position: left 7px;
}

.about-page .dst-box {
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 20px;
  min-height: 455px;
  color: #fff;
  width: 90%;
}

.about-page .dst-box .text-box {
  border-radius: 20px;
  padding: 20px 25px;
  position: absolute;
  bottom: -15px;
  width: 95%;
  left: 0;
  right: 0;
  margin: auto;
  /*min-height: 298px;*/
}

.about-page .dst-box.one .text-box {
  background: rgba(240, 83, 104, 0.9);
}

.about-page .dst-box.two .text-box {
  background: rgba(167, 97, 165, 0.9);
}

.about-page .dst-box.three .text-box {
  background: rgba(167, 203, 73, 0.9);
}

.about-page .dst-box .text-box ul {
  padding: 0 0 0 10px;
  /* min-height: 164px; */
}

/* .about-page .dst-box .text-box li {
 background-image: url(../images/bullet.png);
 background-repeat: no-repeat;
 padding: 0px 0px 10px 19px;
 list-style: none;
 background-position: left 7px;
} */
.about-page .dst-box .text-box li {
  list-style: none;
  font-size: 17px;
  text-align: center;
}

.about-page .dst-box .text-box ul {
  padding: 0;
}

.about-page .dst-box .text-box h2 {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.about-page .dst-box .text-box .btn-primary {
  background-color: #fff;
  width: 170px;
  margin: auto;
  padding: 8px 0;
  display: block;
}

.about-page .dst-box.one .text-box .btn-primary {
  color: #f05368 !important;
  border: 2px solid #fff;
}

.about-page .dst-box.one .text-box .btn-primary:hover {
  background-color: transparent !important;
  color: #fff !important;
  border: 2px solid #fff !important;
}

.about-page .dst-box.two .text-box .btn-primary {
  color: #b266a6 !important;
  border: 2px solid #fff !important;
}

.about-page .dst-box.two .text-box .btn-primary:hover {
  background-color: transparent !important;
  color: #fff !important;
  border: 2px solid #fff !important;
}

.about-page .dst-box.three .text-box .btn-primary {
  color: #a7cb49 !important;
  border: 2px solid #fff !important;
}

.about-page .dst-box.three .text-box .btn-primary:hover {
  background-color: transparent !important;
  color: #fff !important;
  border: 2px solid #fff !important;
}

.about-page .dst-box.one {
  /* background-image: url(../images/destinationimg1.jpg); */
}

.about-page .dst-box.two {
  /* background-image: url(../images/destinationimg2.jpg); */
}

.about-page .dst-box.three {
  /* background-image: url(../images/destinationimg3.jpg); */
}

.about-page .partners-slider {
  background-color: #f7f7f7;
  padding: 50px 0 50px 0;
  width: 121%;
}

.about-page.partners-row img {
  width: auto !important;
}

.about-page.partners-row .slick-slide {
  text-align: center;
  margin: 0 25px;
}

.about-page.ourThemes .icon-box {
  display: inline-block;
  vertical-align: top;
  width: auto;
  text-align: center;
  margin: 0 35px 15px 35px;
  text-align: center;
}

.about-page.ourThemes .icon-box h2 {
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0 0 0;
}

.about-page.ourThemes .education-text {
  color: #70bbe8;
}

.about-page.ourThemes .health-text {
  color: #f2748d;
}

.about-page.ourThemes .livelihood-text {
  color: #1aafbd;
}

.about-page.ourThemes .disability-text {
  color: #c4da56;
}

.about-page.ourThemes .environment-text {
  color: #5090c9;
}

.about-page.ourThemes .human-text {
  color: #aece41;
}

.about-page.ourThemes .elderly-text {
  color: #dce575;
}

.about-page.ourThemes .children-text {
  color: #f3e37b;
}

.about-page.ourThemes .women-text {
  color: #f6a4b1;
}

.about-page.ourThemes .community-text {
  color: #73ccd5;
}

.about-page .team-row img {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.about-page .team-row .imgBox {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.about-page .team-row .imgBox .names {
  position: absolute;
  text-align: center;
  z-index: 5;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #979797;
  padding: 5px 20px;
  left: 80%;
  top: 37%;
  width: max-content;
  display: none;
}

.about-page .team-row .imgBox:hover .names {
  display: inline-block;
}

.about-page .team-row .imgBox .names h2 {
  color: #f05368;
  font-weight: 700;
  font-size: 18px;
  margin: 0;
}

.about-page .team-row .imgBox .names h2 span {
  display: block;
  color: #7b7b7b;
  font-weight: 600;
  font-size: 15px;
}

.about-page .team-row .ashwani {
  top: 209px;
  margin-left: -223px;
}

.about-page .team-row .shravan {
  top: 102px;
  margin-left: -48px;
}

.about-page .team-row .raghavendra {
  top: 308px;
  margin-left: -219px;
}

.about-page .team-row .bala {
  margin-left: -45px;
  top: 7px;
}

.about-page .team-row .zam {
  margin-left: -213px;
  top: 191px;
}

.about-page .team-row .vadivel {
  margin-left: -20px;
  top: 111px;
}

.about-page .team-row .deba {
  margin-left: -200px;
  top: 280px;
}

.about-page .team-row .meenal {
  margin-left: -9px;
  top: 38px;
}

.about-page .team-row .apurva {
  margin-left: -149px;
  top: 178px;
}

.about-page .team-row .bhagya {
  margin-left: -28px;
  top: 106px;
}

.about-page .team-row .anirudh {
  margin-left: -27px;
  top: 169px;
}

.about-page .team-row .manikandan {
  margin-left: -144px;
  top: 239px;
}

.about-page .team-row {
  min-height: 500px;
}

.about-page.community-row {
  /*background:url(../images/community-row-bg.png); */
  background-position: right;
  /* background: linear-gradient(90deg, rgba(255,255,255,1) 15%, rgba(247,247,247,1) 15%); */
}

.about-page.community-row h3 {
  line-height: 45px;
}

.about-page.community-row h3 span {
  font-size: 35px;
  display: block;
  line-height: 23px;
}

.about-page .footer-about {}

.about-page .footer-about .fa {
  font-size: 20px;
}

.about-page .footer-about .fa-instagram {
  color: rgb(231, 36, 125);
}

.about-page .footer-about .fa-facebook {
  color: rgb(52, 96, 163);
  font-size: 20px;
}

.about-page .footer-about .fa-twitter {
  color: rgb(5, 158, 245);
}

.about-page .footer-about .fa {
  margin: 0px 3px;
}

.about-page .footer-about .yt {
  width: 20px;
  display: inline-block;
  vertical-align: sub;
  margin-left: 4px;
  top: -1px;
  position: relative;
}

.about-page .footer-about a:hover {
  text-decoration: none;
}

.about-page .footer-about a,
.about-page .footer-about p {
  color: #696969;
}

.about-page .footer-about p {}

.howitWorks-slider .slick-dots li button:before {
  font-size: 15px;
  opacity: 1;
  color: #e5e5e5;
}

.howitWorks-slider .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #f05368;
}

.howitWorks-slider .slick-dots {
  bottom: -39px;
}

.howitWorks-slider img {
  border-radius: 8px;
  display: block;
  width: 100%;
}

.howitWorks-slider .slick-prev:before {
  /*content: url(../images/howit-prev.png);*/
  opacity: 1 !important;
}

.howitWorks-slider .slick-prev:before {
  /*  content: url(../images/howit-prev.png);*/
  opacity: 1 !important;
}

.howitWorks-slider .slick-next:before {
  /*content: url(../images/howit-next.png);*/
  opacity: 1 !important;
}

.howitWorks-slider .slick-next,
.howitWorks-slider .slick-prev {
  width: 44px;
  height: 45px;
  z-index: 1;
}

.howitWorks-slider .slick-next {
  right: 0;
}

.howitWorks-slider .slick-prev {
  left: 0;
}

.howitWorks-slider .slick-slide {
  margin: 0 0;
}

.how-itworks-video iframe {
  width: 100%;
  height: 500px;
}

.middle-col .logo {
  width: 325px;
  margin-left: -342px;
  border-bottom: 1px solid #e5e5e5;
  top: 0;
  z-index: 5;
  background: #fff;
  display: none;
}

.middle-col .logo img {
  width: 318px;
}

.middle-col .createPost {
  border: 1px solid #d3d3d3;
  background: #efefef;
  border-radius: 6px;
  /* padding: 10px 15px; */
  /* min-height: 130px; */
}

.middle-col .w-100__control {
  height: 130px !important;
}

.middle-col .createPost textarea {
  height: 100% !important;
  overflow: auto !important;

  padding: 10px 15px !important;
}

.middle-col .createPost .w-100__highlighter {
  height: 100% !important;
}

.middle-col .createPost .w-100__suggestions {
  border-radius: 15px;
  height: 250px;
  overflow: auto;
  padding: 15px 25px 25px 25px !important;
  background: #f05368 !important;
}

.middle-col .createPost ul li {
  color: #fff;
  font-size: 15px !important;
  margin-bottom: 8px;
}

.middle-col .createPost ul li:hover {
  color: #000;
}

.middle-col .post-icons {
  position: absolute;
  right: 27px;
  bottom: 17px;
  display: flex;
}

.middle-col .post-icons .btn-primary {
  font-size: 14px;
  padding: 5px 30px;
  border-radius: 3px;
  margin: 0 3px;
}

.middle-col .post-icons select {
  background-color: #bebebe;
  border: 0 !important;
  color: #fff;
  font-size: 13px;
  width: 190px;
  height: calc(1.6em + 0.75rem + 2px);
  margin: 0 3px;
}

.middle-col .post-icons a {
  margin: 0 3px;
}

.middle-col .header {
  position: sticky;
  top: 0;
  z-index: 5;
  background: #fff;
}

.icon-comment {
  cursor: pointer;
}
 .toggleBtn {
  position: absolute;
  top: 25px;
  /* left: 10px; */
  left: 36px;
  display: none;
}

.header .mob-header-icons {
  position: absolute;
  right: 25px;
  top: 30px;
  display: none;
}

.header .mob-header-icons a:nth-child(1) {
  display: none;
}

.header .mob-header-icons img {
  margin-right: 5px;
}

.header .input-group {
  width: 300px;
}

.header .input-group .btn {
  padding: 0.2rem 0.75rem;
}

.form-control {
  /* border: 0 !important;
  background: #e5e5e5; */
  font-size: 15px;
}

.form-control.border {
  border: 1px solid #bebebe !important;
}

.middle-col .input-group .fa {
  color: #bebebe;
}

.middle-col .top-tabs .inner {
  width: 100%;
}

.middle-col .top-tabs a {
  font-size: 16px;
  color: #696969;
  padding: 10px 0;
  text-align: center;
  flex: auto;
}

.middle-col .top-tabs a.active-postTab {
  color: #fff !important;
  background: #f05368 !important;
  cursor: pointer;
}

.middle-col .top-tabs a.all-tab {
  color: #fff;
  padding: 10px 15px;
  width: auto;
  text-align: center;
  background: #efefef;
  color: rgb(105, 105, 105);
  flex: inherit;
  cursor: pointer;
}

.middle-col .top-tabs a:nth-child(2) {
  background: #f7f7f7;
  cursor: pointer;
}

.middle-col .top-tabs a:nth-child(3) {
  background: #efefef;
  cursor: pointer;
}

.middle-col .top-tabs a:nth-child(4) {
  background: #e5e5e5;
  cursor: pointer;
}

.middle-col .top-tabs a:nth-child(5) {
  background: #dddddd;
  cursor: pointer;
}

.middle-col .top-tabs a:nth-child(6) {
  background: #f7f7f7;
  cursor: pointer;
}

.middle-col .top-tabs a:nth-child(7) {
  background: #efefef;
  cursor: pointer;
}

.middle-col .top-tabs a:nth-child(8) {
  background: #e5e5e5;
}

.middle-col .top-tabs a:nth-child(9) {
  background: #dddddd;
  cursor: pointer;
}

.middle-col .top-tabs a:hover {
  text-decoration: none;
}

.middle-col .dropdown {
  /* right: 18px; */
  /* position: absolute; */
  cursor: pointer;
}

.middle-col .dropdown .fa-ellipsis-h {
  color: #696969;
  font-size: 25px;
}

.dropdown-menu {
  padding: 0;
  font-size: 14px;
}

.middle-col .ngo-middle {
  /*padding-top: 175px;*/
  /* padding-top: 94px; */
  padding-top: 25px;
}

.ngo-middle-dashboard {
  /*padding-top: 175px;*/
  padding-top: 0px !important;
}

.middle-col .ngo-middle .btn-primary-blue,

.middle-col .ngo-middle .btn-primary-outline,
.middle-col .ngo-middle .btn-primary,
.middle-col .ngo-inner-middle .btn-primary,
.middle-col .ngo-inner-middle .btn-primary-outline,
.donate-middle .btn-primary-outline,
.donate-middle .btn-primary,
.donate-middle-inner .btn-primary-outline,
.donate-middle-inner .btn-primary {
  width: 115px;
  padding: 8px 0px;
}

.middle-col .ngo-middle .btn-primary-green {
  /* width: 115px;
  padding: 2px 9px; */
  width: 120px;

  /* padding: 2px 11px; */

}

.tabs-sticky {
  position: sticky !important;
  top: 52px;
  z-index: 5;
}

.ngo-top-icons {
  position: fixed;
  z-index: 5;
  background-color: #fff;
  max-width: 998px;
  width: 100%;
}

/* .ngo-top-icons img {
 width: 84px;
} */
.don-receive {
  width: 284px;
  padding-left: 0;
  padding-right: 0;
  font-size: 12px;
  line-height: 22px;
}

.don-receive span {
  font-size: 32px;
}

.ngo-top-icons a {
  margin: 0 3px;
  width: 84px;
  line-height: 84px;
  text-align: center;
  background-color: #bebebe;
  font-size: 18px;
  color: #fff;
  border-radius: 6px;
  transition: all 0.3s;
  display: inline-block;
  cursor: pointer;
}

.ngo-top-icons a img {
  width: 55px;
  height: 55px;
}

/* THEME OVER CSS */
.active-all {
  background-color: #ae5ea2 !important;
}

.theme-icon-1:hover {
  background-color: #f3e37b !important;
}

.theme-icon-2:hover {
  background-color: #73ccdf !important;
}

.theme-icon-3:hover {
  background-color: #c4da56 !important;
}

.theme-icon-4:hover {
  background-color: #70bbe8 !important;
}

.theme-icon-5:hover {
  background-color: #dce575 !important;
}

.theme-icon-6:hover {
  background-color: #aece41 !important;
}

.theme-icon-7:hover {
  background-color: #f2748d !important;
}

.theme-icon-8:hover {
  background-color: #5090c9 !important;
}

.theme-icon-9:hover {
  background-color: #1aafbd !important;
}

.theme-icon-10:hover {
  background-color: #f6a4b1 !important;
}

/* THEME OVER CSS */
.ngo-top-icons .education-active,
.ngo-top-icons a.icon-education:hover {
  background-color: #70bbe8;
}

.ngo-top-icons .health-active,
.ngo-top-icons a.icon-health:hover {
  background-color: #f2748d;
}

.ngo-top-icons .livelihood-active,
.ngo-top-icons a.icon_Livelihood:hover {
  background-color: #1aafbd;
}

.ngo-top-icons .disability-active,
.ngo-top-icons a.icon-Disability:hover {
  background-color: #c4da56;
}

.ngo-top-icons .children-active,
.ngo-top-icons a.icon-Children:hover {
  background-color: #f3e37b;
}

.ngo-top-icons .women-active,
.ngo-top-icons a.icon-Women:hover {
  background-color: #f6a4b1;
}

.ngo-top-icons .enviornment-active,
.ngo-top-icons a.icon-Enviornment:hover {
  background-color: #aece41;
}

.ngo-top-icons .disaster-active,
.ngo-top-icons a.icon-Disaster_management:hover {
  background-color: #5090c9;
}

.ngo-top-icons .community-active,
.ngo-top-icons a.icon-Community-development:hover {
  background-color: #73ccd5;
}

.ngo-top-icons .research-active,
.ngo-top-icons a.icon-Research:hover {
  background-color: #dce575;
}

.ngo-top-icons a.active-all {
  width: 84px;
  line-height: 84px;
  text-align: center;
  background-color: #f05368;
  font-size: 18px;
  color: #fff;
  border-radius: 6px;
}

.bg-purplle {
  background-color: #ae5ea2 !important;
}

.bg-green {
  background-color: #adce41 !important;
}

/* .ngo-fix-header {
 position: fixed;
 z-index: 5;
 background: #fff;
 top: 52px;
} */
/* .ngo-fix-header .title{
font-size: 100px!important;
} */
.donate-middle {
  margin-top: 100px;
}

/* .rightCol{
 overflow: auto;
 background-color: #ccc;
 display: table;
} */
.middleBox {
  height: 1200px;
}

.rightCol select {
  background: transparent;
  border: 1px solid #bebebe !important;
  width: 80%;
  font-size: 14px;
  color: #484848;
  font-weight: 600;
  padding-left: 5px;
}

.rightCol .testimoni-slider .slick-dots li button:before {
  font-family: "slick";
  font-size: 45px !important;
  line-height: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  content: "-" !important;
  text-align: center;
  opacity: 1;
  color: #ccc;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rightCol .testimoni-slider .slick-dots li.slick-active button::before {
  opacity: 1;
  color: #ae5ea2 !important;
}

.rightCol .testimoni-slider .slick-dots {
  bottom: inherit;
  top: -20px;
  text-align: left;
  padding-left: 10px;
}

.testimoni-slider .slick-slide {
  margin: 0 0px;
}

.testimoni-slide-img {
  width: 100%;
  height: 127px;
  object-fit: cover;
}

.ngo-top-icons select {
  background: transparent;
  border: 1px solid #bebebe !important;
  width: 80%;
  font-size: 18px;
  color: #484848;
  font-weight: 600;
  margin: auto;
}

#selectCategoryModal select {
  background: transparent;
  font-size: 18px;
  color: rgb(72, 72, 72);
  font-weight: 600;
  margin: 5px auto;
  border: 1px solid rgb(190, 190, 190) !important;
}

#selectCategoryModal .form-control {
  height: calc(1.8em + 0.75rem + 2px) !important;
}

.middle-col .ngo-middle .ngo-profile-img {
  /* position: absolute;
  margin-left: 30px;
  top: 38px;
  width: 87px; */
  position: absolute;
  margin-left: 30px;
  top: 38px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

/* .middle-col .ngo-middle .user-profile-img {
  position: absolute;
  margin-left: 30px;
  top: 9px;
} */
.profile-page .profile-thumb {
  width: 80px;
}

.middle-col .ngo-middle .user-profile-img {
  position: absolute;
  margin-left: 30px;
  top: 12px;
  width: 80px;
}

.middle-col .ngo-middle .imghexa {
  transform: scale(1.2);
}

.btn-link-primary {
  color: #f05368;
}

span.count {
  font-size: 14px;
  color: #3d5252;
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
}

.ngo-slider .btn-primary-outline {
  font-size: 12px;
  padding: 4px 18px;
  margin-top: 15px;
}

.ngo-slider img {
  display: block;
  margin: auto;
}

.ngo-slider .tick {
  position: absolute;
  right: 10px;
  top: 10px;
}

.ngo-slider .slick-prev {
  left: inherit;
  right: 40px;
}

.ngo-slider .slick-next {
  right: 10px;
}

.ngo-slider .slick-prev,
.ngo-slider .slick-next {
  top: -25px;
}

.products-slider .btn-buy {
  background-color: #1aafbd;
  color: #fff;
  font-size: 14px;
  padding: 0.2rem 1.5rem;
  border: 2px solid #1aafbd;
}

.products-slider .btn-buy:hover {
  background-color: #fff;
  color: #1aafbd;
}

.recent-slider .btn-buy {
  background-color: #1aafbd;
  color: #fff;
  font-size: 14px;
  padding: 0.2rem 1.5rem;
  border: 2px solid #1aafbd;
}

.trending-slider .slick-dots {
  padding-left: 0;
  text-align: left !important;
  bottom: -46px;
  width: auto;
}

.trending-slider .slick-slide {
  margin: 0 !important;
}

.trending-slider .slick-dots li button:before {
  font-size: 12px;
}

.trending-slider .slick-dots li {
  margin: 0;
}

.trending-slider .readMoreText {
  color: #000 !important;
  font-size: 14px;
  text-decoration: underline;
}

.our-partners.directors img {
  max-width: 102px;
  height: auto;
}

.partners-slider .slick-cloned {
  height: auto !important;
  opacity: 1 !important;
}

.recent-slider .slick-slide {
  margin: 0 0;
}

.recent-slider .btn-buy:hover {
  background-color: #fff;
  color: #1aafbd;
}

.products-slider .slick-prev {
  left: inherit;
  right: 40px;
}

.products-slider .slick-next {
  right: 10px;
}

.products-slider .slick-prev,
.products-slider .slick-next {
  top: -25px;
}

.slick-slide {
  margin: 0 10px;
}

.footer {
  background: #696969;
  position: fixed;
  bottom: 0;
}

.footer .container {
  max-width: 1000px;
}

.footer h3 {
  font-size: 20px;
  color: #fff;
  margin: 0;
}

.footer h3 span {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
}

.footer .btn-primary,
.footer .btn-secondary {
  padding: 6px 30px;
  border-radius: 4px;
  margin-left: 7px;
}

.footer .btn-secondary:hover {
  border: 2px solid #fff !important;
  background: transparent;
  color: #fff !important;
}

.footer .mobile-icon-footer {
  display: none;
}

.footer .mobile-icon-footer img {
  margin: 0 10px;
  display: inline-block;
  vertical-align: middle;
}

.footer .mobile-icon-footer .mid-btns {
  display: inline-block;
  vertical-align: middle;
}

.footer .mobile-icon-footer .mid-btns a.icon-btns1 {
  background-color: #f05368;
  color: #fff;
  font-size: 15px;
  text-align: center;
  padding: 6px 13px 6px 0;
  border-radius: 20px 0 0 20px;
  display: table;
  float: left;
  border-right: 1px solid #fff;
}

.footer .mobile-icon-footer .mid-btns a.icon-btns1 img {
  display: inline-block;
  vertical-align: sub;
}

.footer .mobile-icon-footer .mid-btns a.icon-btns2 {
  background-color: #f2748d;
  color: #fff;
  font-size: 15px;
  text-align: center;
  padding: 7px 0px 6px 13px;
  border-radius: 0 20px 20px 0;
  display: table;
  float: left;
}

.footer .mobile-icon-footer .mid-btns a.icon-btns2 img {
  display: inline-block;
  vertical-align: sub;
}

.footer .mobile-icon-footer .mid-btns a.icon-btns img {
  display: inline-block;
  vertical-align: middle;
}

.new-here {
  position: fixed;
  max-width: 288px;
}

.recent-slider .slick-dots li {
  margin: 0;
}

.recent-slider .slick-dots li button:before {
  font-size: 12px;
}

.recent-slider .slick-dots {
  padding-left: 10px;
  text-align: left !important;
  /*bottom: -56px;*/
  bottom: -30px;
  width: auto;
}

.people_right img {
  display: inline-block;
  vertical-align: middle;
}

.people_right h3 {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.modal h3 {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  margin: 0px 0px 22px -14px;
}

.modal .form-control {
  height: calc(2.3em + 0.75rem + 2px);
}

.modal .btn-secondary,
.modal .btn-primary,
.modal .btn-primary-outline {
  padding: 8px 40px;
  /* display: table;
 margin: 25px auto 10px auto; */
}

.modal a {
  color: #8e8e8e;
  font-size: 13px;
  text-decoration: underline;
}

.modal-sm {
  max-width: 400px;
}

.modal-body {
  padding: 1rem 1.7rem;
}

.modal-body p span.line {
  width: 35px;
  border-top: 1px solid #ccc;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
}

.modal-body .social-icon {
  width: 34px;
  line-height: 34px;
  text-align: center;
  background: #bebebe;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  margin: 0 5px;
  transition: all 0.3s;
}

.modal-body .social-icon:hover {
  background: #f05368;
}

.modal-body .tabs {
  width: 50%;
  background: #e5e5e5;
  color: #696969;
  cursor: pointer;
  font-size: 18px;
  float: left;
  border-radius: 0;
  padding: 0.8rem 0.75rem;
}

/* .modal-body .tabs:hover{
 background: #f05368;
 color: #fff;
} */
.modal-body .tabs.active {
  background: #f05368;
  color: #fff;
  position: relative;
}

.modal-body .tabs.active:after,
.modal-body .tabs.active:before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f05368;
  bottom: -10px;
  content: "";
  left: 60%;
  margin-left: -20px;
  position: absolute;
}

.modal .createPost {
  border: 1px solid #d3d3d3;
  /* background: #EFEFEF; */
  border-radius: 6px;

  margin-bottom: 10px;
}

.modal .createPost .w-100__control {
  height: 130px !important;
}

.modal .createPost textarea {
  height: 100% !important;
  overflow: auto !important;

  padding: 10px 15px !important;
}

.modal .createPost .w-100__highlighter {
  height: 100% !important;
}

.modal .createPost .w-100__suggestions {
  border-radius: 15px;
  height: 250px;
  overflow: auto;
  padding: 15px 25px 25px 25px !important;
  background: rgba(240, 83, 104, 1) !important;
}

.modal .createPost ul li {
  color: #fff;
  font-size: 15px !important;
  margin-bottom: 8px;
}

.modal .createPost ul li:hover {
  color: #000;
}

.about-side-menu .imghexa {
  width: 72px;
  height: auto;
  margin: 5px 5px 5px 0;
}

.w-100__suggestions {
  scrollbar-face-color: #367cd2;
  scrollbar-shadow-color: #ffffff;
  scrollbar-highlight-color: #ffffff;
  scrollbar-3dlight-color: #ffffff;
  scrollbar-darkshadow-color: #ffffff;
  scrollbar-track-color: #ffffff;
  scrollbar-arrow-color: #ffffff;
}

.w-100__suggestions::-webkit-scrollbar {
  width: 12px;
}

.w-100__suggestions::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

.w-100__suggestions::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.modal .post-icons {
  width: 100%;
}

.modal .post-icons .btn-primary {
  margin: 0;
  padding: 8px 35px;
}

.modal .post-icons a {
  margin: 0 5px 0 0;
}

.modal .fa-share-alt {
  background: transparent;
}

.modal .fa-share-alt:hover {
  background: transparent;
}

.modal .btn-primary:hover .fa-share-alt {
  color: rgb(240, 83, 104);
}

/* .modal .share-btn:hover .share-box{
 display: block!important;
} */
.modal .share-btn .fa {
  margin-right: 10px;
}

.modal .share-btn .fa {
  margin-right: 10px;
}

.modal .share-box {
  background-color: rgb(72, 72, 72);
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  max-width: 340px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 110px;
  display: none;
  width: 90%;
}

.modal .share-box:before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgb(72, 72, 72);
  bottom: -10px;
  content: "";
  left: 55%;
  margin-left: -20px;
  position: absolute;
}

.modal .share-box p {
  color: #fff;
  margin: 0;
}

.modal .share-box .fa {
  width: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  color: rgb(255, 255, 255);
  font-size: 16px;
  margin: 0px 5px;
  transition: all 0.3s ease 0s;
}

.modal .share-box .fa-facebook {
  background: #3b5998;
}

.modal .share-box .fa-twitter {
  background: #55acee;
}

.modal .share-box .fa-twitter {
  background: #55acee;
}

.modal .share-box .fa-gmail {
  background: #fff;
  width: 36px;
  line-height: 35px;
  top: -3px;
  position: relative;
}

.modal .share-box .fa-gmail img {
  width: 20px;
}

.modal .share-box .logo {
  top: -5px;
  position: relative;
  width: 36px;
}

.modal .share-box .fa-wtsapp {
  background: #00bd00;
}

.modal .share-box .fa-linkedin {
  background: #007bb5;
}

#postModal .big-img {
  width: 100%;
}

.post-big-img {
  cursor: pointer;
}

#postModal .comments {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

#inviteFriendModal .modal-body:before {
  border-left: 17px solid transparent;
  border-right: 30px solid transparent;
  border-top: 70px solid rgb(255, 255, 255);
  bottom: -43px;
  content: "";
  left: 40%;
  margin-left: -20px;
  position: absolute;
  transform: rotate(45deg);
}

.rightBottom {
  max-width: 290px;
  width: 100%;
}

.rightfix {
  max-width: 290px;
  width: 100%;
  position: fixed;
  padding-top: 0;
  transition: all 0.5s;
}

.rightSmallHeight {
  height: 965px;
}

.profile-page .profile-thumb {
  position: absolute;
  top: -37px;
  left: 30px;
}

polygon {
  stroke: #aaa !important;
  stroke-width: 6 !important;
}

.profile-page .profile-thumb .photo-camera1 {
  position: absolute;
  background: rgb(240, 83, 104);
  z-index: 1;
  width: 30px;
  right: 15px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  bottom: 0px;
  opacity: 0;
}

.profile-page .profile-thumb .photo-camera1 img {
  width: 17px;
  display: block;
  margin: auto;
}

.profile-page .profile-thumb:hover .photo-camera1 {
  opacity: 1;
  transition: all 0.3s;
}

.profile-page .top-cover {
  position: relative;
}

.profile-page .top-cover-img:hover .photo-camera2 {
  opacity: 1;
  transition: all 0.3s;
}

.profile-page .top-cover-img:hover .photo-camera2 .form-control-file {
  display: block;
  width: 100%;
  opacity: 0 !important;
  margin-top: -19px;
  width: 29px;
  overflow: hidden;
  cursor: pointer;
}

.profile-page .top-cover .photo-camera2 {
  position: absolute;
  background: rgb(240, 83, 104);
  z-index: 1;
  width: 30px;
  right: 10px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  bottom: 10px;
  opacity: 0;
}

.profile-page .top-cover .photo-camera2 img {
  width: 17px;
  display: block;
  margin: auto;
  position: relative;
  top: 5px;
}

.colorful-icons img {
  filter: grayscale(1);
}

.colorful-icons a {
  margin: 0 3px 0 0;
  width: 35px;
  line-height: 35px;
  text-align: center;
  background-color: #bebebe;
  font-size: 18px;
  color: #fff;
  border-radius: 6px;
  transition: all 0.3s;
  display: inline-block;
}

.colorful-icons a img {
  width: 23px;
  height: 23px;
}

.colorful-icons a.icon-education:hover {
  background-color: #70bbe8;
}

.colorful-icons a.icon-health:hover {
  background-color: #f2748d;
}

.colorful-icons a.icon_Livelihood:hover {
  background-color: #1aafbd;
}

.colorful-icons a.icon-Disability:hover {
  background-color: #c4da56;
}

.colorful-icons a.icon-Children:hover {
  background-color: #f3e37b;
}

.colorful-icons a.icon-Women:hover {
  background-color: #f6a4b1;
}

.colorful-icons a.icon-Enviornment:hover {
  background-color: #aece41;
}

.colorful-icons a.icon-Disaster_management:hover {
  background-color: #5090c9;
}

.colorful-icons a.icon-Community-development:hover {
  background-color: #73ccd5;
}

.colorful-icons a.icon-Research:hover {
  background-color: #dce575;
}

.donate-middle-inner .profile-thumb,
.ngo-inner-middle .profile-thumb {
  position: absolute;
  top: -35px;
  left: 30px;
  width: 80px;
}

.ngo-inner-middle .top-cover {
  position: relative;
}

.rightCol ul.menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.rightCol ul.menu-list li {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 5px;
}

.rightCol ul.menu-list li a {
  font-size: 18px;
  color: #434343;
}

.rightCol ul.menu-list li a:hover {
  color: #f05368;
  text-decoration: none;
}

.rightCol ul.menu-list li a img {
  color: rgb(67, 67, 67);
  margin-right: 15px;
  display: inline-block;
  vertical-align: sub;
}

.rightCol .volunteer-requir .btn-secondary {
  font-weight: 600 !important;
  padding: 8px 13px;
}

.rightCol .volunteer-requir .btn-secondary:hover {
  background: #fff;
  border: 2px solid #6c757d !important;
  color: #6c757d !important;
}

.calendar-height {
  min-height: 325px;
}

/* .side-box-height{
 height: fit-content;
 background: red;
} */
.volun-calendar select {
  background: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 700;
  padding: 0.1rem 0.3rem;
  height: calc(1rem + 0.75rem + 2px) !important;
  width: auto;
  border: 1px solid rgb(204, 204, 204) !important;
}

ul.mobilemenu-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
}

ul.mobilemenu-list li {
  padding: 0px 7px 0 0;
  display: inline-block;
}

ul.mobilemenu-list li a {
  font-size: 14px;
  color: #434343;
}

ul.mobilemenu-list li a:hover {
  color: #f05368;
  text-decoration: none;
}

ul.mobilemenu-list li a img {
  color: rgb(67, 67, 67);
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
}

.volun-calendar .weekdays {
  margin: 0;
  padding: 0 0;
  width: 100%;
}

.volun-calendar .weekdays li {
  display: inline-block;
  width: 13%;
  color: #666;
  text-align: center;
  font-size: 13px;
  color: #484848;
  font-weight: 700;
}

.volun-calendar .days {
  padding: 10px 0;
  margin: 0;
}

.volun-calendar .days li {
  list-style-type: none;
  display: inline-block;
  width: 13%;
  text-align: center;
  margin-bottom: 10px;
  font-size: 12px;
  color: #777;
  font-weight: 700;
}

.volun-calendar .days li .active {
  /* padding: 8px; */
  background: rgb(174, 206, 65);
  border-radius: 50%;
  color: white !important;
  width: 30px;
  display: block;
  line-height: 30px;
  margin: auto;
}

#otpModal .form-control {
  width: 50px;
  display: inline-block;
  margin: 5px;
}

#otpModalNgo .form-control {
  width: 50px;
  display: inline-block;
  margin: 5px;
}

#otpModalForgot .form-control {
  width: 50px;
  display: inline-block;
  margin: 5px;
}

#comProfileModal .profile-pic {
  position: relative;
  width: max-content;
  margin: auto;
}

#comProfileModal .camera-icon {
  position: absolute;
  right: 0;
  bottom: 0;
}

#comProfileModal .form-control {
  width: 250px;
  height: calc(1.8em + 0.75rem + 2px);
}

#comProfileModal label {
  font-size: 14px;
  color: #696969;
}

#comProfileModal textarea {
  height: 100px !important;
}

#comProfileModal .modal-dialog {
  max-width: 586px;
}

#comProfileModal .date-field {
  width: 137px !important;
  border: 1px solid rgb(204, 204, 204) !important;
  padding-left: 5px;
  font-size: 12px;
  height: calc(2.2em + 0.75rem + 2px);
}

#comProfileModal select {
  background: #fff;
  border: 1px solid #ccc !important;
}

#selectThemeModal .modal-dialog {
  max-width: 586px;
}

.select-white {
  background: #fff;
  border: 1px solid #ccc !important;
  height: calc(1.8em + 0.75rem + 2px) !important;
  padding-left: 5px;
}

#volunteerNowModal .modal-dialog {
  max-width: 620px;
}

#selectThemeModal a {
  margin: 0 3px;
  width: 95px;
  line-height: 95px;
  text-align: center;
  background-color: #bebebe;
  font-size: 18px;
  color: #fff;
  border-radius: 6px;
  transition: all 0.3s;
  display: inline-block;
  margin: 5px 3px;
  position: relative;
}

#selectThemeModal a img {
  width: 55px;
  height: 55px;
}

#selectThemeModal .red-dot {
  width: 14px;
  height: 14px;
  background-color: #f05368;
  border-radius: 50%;
  position: absolute;
  right: 7px;
  top: -5px;
  display: none;
}

#selectThemeModal .icons-name {
  position: absolute;
  color: #484848;
  z-index: 5;
  top: 60px;
  left: 0;
  right: 0;
  font-size: 13px;
  display: none;
}

#selectVolunteerModal .modal-dialog {
  max-width: 586px;
}

#selectVolunteerModal .form-control {
  max-width: 260px !important;
  height: calc(1.8em + 0.75rem + 2px);
  margin: auto;
}

#selectVolunteerModal select {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204) !important;
}

#selectVolunteerModal label {
  font-size: 14px;
  color: #696969;
}

#selectVolunteerModal .date-field {
  width: 137px !important;
  border: 1px solid rgb(204, 204, 204) !important;
  padding-left: 5px;
  font-size: 12px;
  height: calc(2.2em + 0.75rem + 2px);
}

#selectVolunteerModal2 .form-control {
  max-width: 260px !important;
  height: calc(1.8em + 0.75rem + 2px);
  margin: auto;
}

#selectVolunteerModal2 select {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204) !important;
}

#selectVolunteerModal2 label {
  font-size: 14px;
  color: #696969;
}

#selectVolunteerModal2 .date-field {
  width: 137px !important;
  border: 1px solid rgb(204, 204, 204) !important;
  padding-left: 5px;
  font-size: 12px;
  height: calc(2.2em + 0.75rem + 2px);
}

#adhaarPanModal .form-control {
  height: calc(1.8em + 0.75rem + 2px);
}

#followNGOsModal .modal-dialog {
  max-width: 586px;
}

#followNGOsModal .input-group {
  max-width: 326px;
  margin: auto;
}

#followNGOsModal .tick {
  position: absolute;
  right: 10px;
  top: 10px;
}

/* #followNGOsModal .ngoBox{
 margin: 5px;
} */
/* #selectThemeModal .red-dot-show{
   opacity: 1!important;
} */
#selectThemeModal .education-active {
  background-color: #70bbe8;
}

#selectThemeModal .health-active {
  background-color: #f2748d;
}

#selectThemeModal .livelihood-active {
  background-color: #1aafbd;
}

#selectThemeModal .disability-active {
  background-color: #c4da56;
}

#selectThemeModal .children-active {
  background-color: #f3e37b;
}

#selectThemeModal .women-active {
  background-color: #f6a4b1;
}

#selectThemeModal .enviornment-active {
  background-color: #aece41;
}

#selectThemeModal .disaster-active {
  background-color: #5090c9;
}

#selectThemeModal .community-active {
  background-color: #73ccd5;
}

#selectThemeModal .research-active {
  background-color: #dce575;
}

/*  
@media screen and (max-width:720px) {
 .weekdays li, .days li {width: 13.1%;}
}
@media screen and (max-width: 420px) {
 .weekdays li, .days li {width: 12.5%;}
 .days li .active {padding: 2px;}
}
@media screen and (max-width: 290px) {
 .weekdays li, .days li {width: 12.2%;}
} */
.small-header {
  top: 55px;
  z-index: 5;
  padding: 10px 0 10px 15px;
}

.dark-border {
  border-color: #bebebe !important;
}

#editDetailsModal .form-control {
  width: 250px;
  height: calc(1.8em + 0.75rem + 2px);
}

#editDetailsModal label {
  font-size: 14px;
  color: #696969;
}

#editDetailsModal textarea {
  height: 100px !important;
}

#editDetailsModal .modal-dialog {
  max-width: 586px;
}

#editDetailsModal .date-field {
  width: 137px !important;
  border: 1px solid rgb(204, 204, 204) !important;
  padding-left: 5px;
  font-size: 12px;
  height: calc(2.2em + 0.75rem + 2px);
}

#editDetailsModal select {
  background: #fff;
  border: 1px solid #ccc !important;
}

.theme-update .iconbox {
  display: inline-block;
  vertical-align: top;
  padding-bottom: 15px;
}

.theme-update .theme-icon {
  margin: 0 3px;
  width: 85px;
  line-height: 85px;
  text-align: center;
  background-color: #bebebe;
  font-size: 18px;
  color: #fff;
  border-radius: 6px;
  transition: all 0.3s;
  display: inline-block;
  margin: 5px 3px;
  position: relative;
}

.theme-update img {
  width: 40px;
  height: 40px;
}

.theme-update .custom-checkbox {
  /* padding-left: 32px !important;*/
  margin: auto;
  width: max-content;
}

.theme-update .icons-name {
  color: #484848;
  z-index: 5;
  top: 60px;
  left: 0;
  right: 0;
  font-size: 13px;
}

.theme-update .custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #aaa;
  background-color: #aaa;
}

.volunteer-col {
  margin-top: 10px;
}

.volunteer-col .btn-primary-green {
  padding: 6px 0;
}

.volunteer-col .btn-secondary {
  background: #bebebe;
  font-weight: 600;
  padding: 6px 0;
}

.volunteer-col .btn-secondary:hover {
  background: #fff;
  color: #5a6268 !important;
  border: 2px solid #bebebe !important;
}

.btn-volunteer-now {
  background: #aece41;
  color: #fff !important;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 0;
  border: 2px solid #aece41 !important;
  border-radius: 5px;
  width: 100%;
  position: relative;
  margin: 5px 0;
}

.btn-volunteer-now img {
  position: absolute;
  left: 5px;
  top: 7px;
}

.btn-volunteer-now:hover {
  opacity: 0.9;
}

.existingOpportunities {
  display: none;
}

.mob-volunteerNowModal {
  clear: both;
  margin: 0;
  padding: 5px 15px;
  border: 1px solid #aece41;
  border-radius: 0 0 5px 5px;
  display: none;
}

.donate-middle-inner {
  margin-top: 100px;
}

.gmail-icon button {
  width: 52px !important;
  margin-top: -17px !important;
  box-shadow: none !important;
}

.bydate-fields {
  position: sticky;
  left: 25px;
  top: 93px;
  background: #fff;
  padding: 10px 0px 6px 0;
  z-index: 5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  /* margin-bottom: 65px; */
}

.bydate-fields.reviews-dates-select {
  top: 50px;
  margin-bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

/* .bydate-fields.donation-top-fields {
 margin-bottom: 0!important;
} */
.bydate-fields .date-field {
  width: 135px !important;
  /* font-size: 12px; */
}

.bydate-fields .area-interest {
  width: auto;
}

.select-white.area-interest {
  width: 215px;
}

input::-webkit-datetime-edit {
  position: absolute;
  z-index: 10 !important;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0;
  z-index: 100 !important;
  /* background: url(http://site-demo.in/1.png);
width: 100%; */
  opacity: 0;
}

.ios-input,
.date-field {
  background: url(http://site-demo.in/1.png) !important;
  background-repeat: no-repeat !important;
  background-position: center right !important;
}

.bydate-fields .dates .date-field {
  width: 128px !important;
  /* font-size: 12px; */
}

.bydate-fields .form-control {
  height: calc(1.8em + 0.75rem + 2px);
}

.bydate-fields .small-field {
  width: 120px !important;
}

.reviews-fields {
  position: sticky;
  left: 25px;
  top: 49px;
  background: #fff;
  padding: 10px 0px 6px 0;
  z-index: 5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.ng-donations-tabs {
  top: 50px;
  position: relative;
}

.postOpen-overlay {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  display: none;
}

.postOpen-overlay .fa-ellipsis-h {
  color: #696969;
  font-size: 25px;
  cursor: pointer;
  margin-top: 6px;
}

.postOpen-overlay .name-col {
  left: -40px;
  position: relative;
}

.postOpen-overlay .comments-col .name-col {
  left: -40px;
  position: relative;
}

.postOpen-overlay .comments-col p.comment {
  line-height: 20px;
  margin-top: -20px;
  left: 10px;
  position: relative;
}

.postOpen-overlay .comments-col::-webkit-scrollbar {
  display: none;
}

.postOpen-overlay .cross {
  position: absolute;
  left: 0;
  z-index: 5;
  top: 15px;
  left: 15px;
  cursor: pointer;
}

.postOpen-overlay .postModal-slider {
  padding-top: 8%;
}

.postOpen-overlay .slick-next {
  right: 8%;
}

.postOpen-overlay .slick-prev {
  left: 8%;
}

.postOpen-overlay .slick-prev::before,
.postOpen-overlay .slick-next::before {
  font-size: 30px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  opacity: 0.5 !important;
  color: #f05368 !important;
}

/* 
.slick-slide {
 margin: 0px 20px;
}
*/
.postOpen-overlay .slick-slide img {
  width: 100%;
}

.postOpen-overlay .slick-slide {
  transition: all ease-in-out 0.3s;
  opacity: 0;
}

.slick-active {
  opacity: 1 !important;
}

/* NGO Registration process Start*/
.ngo-registration-header {
  max-width: 1365px;
  border-bottom: 1px solid #e5e5e5;
}

.ngo-registration-header p {
  font-weight: 600;
  color: #696969;
  margin: 0;
}

.registration-steps-numbers {
  max-width: 842px;
}

.registration-steps-numbers .number {
  width: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  background-color: #bebebe;
  color: #fff;
  font-size: 22px;
}

.registration-steps-numbers .number.active {
  background-color: #f05368;
}

.registration-steps-numbers hr {
  width: 25%;
  border-top: 1px solid#bebebe;
}

.registration-steps.form-box {
  max-width: 1125px;
}

.registration-steps.form-box h3 {
  font-weight: 600;
  color: #696969;
}

.registration-steps.form-box .form-control {
  height: calc(1.8em + 0.75rem + 2px);
  font-size: 14px;
}

.registration-steps.form-box .form-group {
  margin-bottom: 1rem;
}

.registration-steps.form-box label {
  font-size: 13px;
  color: #696969;
}

.registration-steps.form-box .date-field {
  width: 134px !important;
}

.registration-steps.form-box .select-white {
  height: calc(1.7em + 0.75rem + 2px) !important;
}

.registration-steps.form-box .btn-save {
  background: transparent;
  border: 1px solid #5090c9;
  color: #5090c9;
  font-size: 14px;
}

.registration-steps.form-box .btn-save:hover {
  border: 1px solid #aaa;
  color: #aaa;
}

.registration-steps.form-box .btn-primary-outline,
.registration-steps.form-box .btn-primary {
  padding: 8px 40px;
}

.ngo-registration-footer {
  margin-top: 80px;
}

.ngo-registration-footer .fa-facebook {
  color: #3460a3;
  font-size: 20px;
}

.ngo-registration-footer .fa-twitter {
  color: #059ef5;
  font-size: 20px;
  margin: 0 12px;
}

.ngo-registration-footer .fa-instagram {
  color: #e7247d;
  font-size: 20px;
  /* // background-image: url(); */
}

.ngo-registration-footer p {
  color: #696969;
}

.registration-steps.brief-information label {
  font-size: 14px;
  color: #696969;
  line-height: 20px;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #f05368;
  background-color: #f05368;
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before,
.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: #f05368;
}

.registration-steps.documentation label {
  height: calc(2em + 0.75rem + 2px);
  padding: 0.7rem 0.75rem;
  background: #e5e5e5;
}

.registration-steps.documentation .custom-file-label::after {
  height: calc(2em + 0.75rem);
  padding: 0.6rem 2rem;
  line-height: 1.5;
  color: #fff;
  content: "Upload";
  background-color: #bebebe;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.registration-steps.select-theme-type .icons-row .education-active {
  background-color: #70bbe8;
}

.registration-steps.select-theme-type .icons-row .health-active {
  background-color: #f2748d;
}

.registration-steps.select-theme-type .icons-row .livelihood-active {
  background-color: #1aafbd;
}

.registration-steps.select-theme-type .icons-row .disability-active {
  background-color: #c4da56;
}

.registration-steps.select-theme-type .icons-row .children-active {
  background-color: #f3e37b;
}

.registration-steps.select-theme-type .icons-row .women-active {
  background-color: #f6a4b1;
}

.registration-steps.select-theme-type .icons-row .enviornment-active {
  background-color: #aece41;
}

.registration-steps.select-theme-type .icons-row .disaster-active {
  background-color: #5090c9;
}

.registration-steps.select-theme-type .icons-row .community-active {
  background-color: #73ccd5;
}

.registration-steps.select-theme-type .icons-row .research-active {
  background-color: #dce575;
}

.registration-steps.select-theme-type .icons-row a {
  margin: 0 3px;
  width: 95px;
  line-height: 95px;
  text-align: center;
  background-color: #bebebe;
  font-size: 18px;
  color: #fff;
  border-radius: 6px;
  transition: all 0.3s;
  display: inline-block;
  margin: 5px 7px;
  position: relative;
}

.registration-steps.select-theme-type .icons-row .icons-name {
  position: absolute;
  color: #484848;
  z-index: 5;
  top: 60px;
  left: 0;
  right: 0;
  font-size: 13px;
  display: none;
}

.registration-steps.select-theme-type .icons-row .red-dot {
  width: 14px;
  height: 14px;
  background-color: #f05368;
  border-radius: 50%;
  position: absolute;
  right: 7px;
  top: -5px;
  display: none;
}

.registration-steps.select-theme-type .innerContainer {
  max-width: 701px;
}

.registration-steps.select-theme-type .big-img-box {
  width: 100%;
  background: #e5e5e5;
  height: 220px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  margin-top: 80px;
}

.registration-steps.select-theme-type .big-img-box .registrationprofile {
  margin-top: -54px;
  margin-left: 30px;
  position: relative;
  width: max-content;
}

.registration-steps.select-theme-type .big-img-box .registrationprofile .camera-icon {
  position: absolute;
  right: 15px;
  bottom: 0;
}

.registration-steps.select-theme-type .big-img-box .camera-icon2 {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.registration-steps.select-theme-type textarea {
  min-height: 110px;
}

.registration-steps.select-theme-type .browse-col label {
  height: calc(1.9em + 0.75rem + 2px);
  padding: 0.6rem 0.75rem;
  background: rgb(229, 229, 229);
}

.registration-steps.select-theme-type .browse-col .custom-file-label::after {
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  /*content: url(../images/camera2.png);*/
  background-color: transparent;
  border: 0;
}

.registration-steps.select-theme-type .browse-col.video .custom-file-label::after {
  /*content: url(../images/video-icon.png);*/
}

.registration-steps.select-theme-type .fa-plus {
  width: 28px;
  line-height: 28px;
  background: #f05368;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  margin-top: 3px;
}

/* NGO Registration process End*/
.eye-purplle {
  background-color: #ae5ea3;
  color: #fff;
  width: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 5px;
}

.eye-blue {
  background-color: #71bbe8;
  color: #fff;
  width: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 5px;
}

.eye-green {
  background-color: #adce41;
  color: #fff;
  width: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 5px;
}

#searchItemsModal .date-field,
#searchDonationsModal .date-field {
  width: auto !important;
  border: 1px solid rgb(204, 204, 204) !important;
  margin: auto;
  font-size: 14px;
  height: calc(2.2em + 0.75rem + 2px);
}

#searchItemsModal .form-control,
#searchDonationsModal .form-control {
  width: auto !important;
  margin: auto;
  font-size: 14px;
  height: calc(2.2em + 0.75rem + 2px);
}

#searchVolunteerModal .date-field {
  border: 1px solid rgb(204, 204, 204) !important;
  margin: auto;
  font-size: 14px;
  height: calc(2.2em + 0.75rem + 2px);
}

#searchVolunteerModal .form-control {
  margin: auto;
}

.h-small {
  height: calc(1.8em + 0.75rem + 2px) !important;
}

.select-small-dark {
  background-color: #bebebe;
  border: 0 !important;
  color: #fff;
  font-size: 13px;
  width: 190px;
  height: calc(1.5em + 0.75rem + 2px) !important;
  margin: 0 3px;
}

#hex {
  stroke-width: 2;
  stroke: #ccc;
  transform: scale(0.8);
}

.hexa-shape {
  height: 79px;
}

.w-14 {
  width: 14% !important;
}

.ngo-about-header {
  background-color: white;
  top: 0;
  z-index: 5;
}

.ngo-about-header .container {
  max-width: 1366px;
}

.ngo-about-header .btn-dark {
  background: #484848;
  font-weight: 600;
  border-radius: 8px;
  padding: 5px 40px;
  border: 2px solid #484848;
}

.ngo-about-header .btn-dark:hover {
  background: transparent;
  border: 2px solid #fff;
}

.ngo-about-header .btn-white {
  background: #ffffff;
  font-weight: 600;
  border-radius: 8px;
  padding: 5px 40px;
  border: 2px solid #ffffff;
  color: #f05368;
}

.ngo-about-header .btn-white:hover {
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
}

.ngo-about-header .input-group {
  width: 253px;
  position: relative;
  top: 2px;
}

.ngo-about-header .input-group .form-control {
  background-color: #b23d4d;
  color: #fff;
  height: calc(1.6em + 0.75rem + 2px);
  border: 0 !important;
}

.ngo-about-header .input-group .form-control:focus {
  border: 0 !important;
}

.ngo-about-header .input-group .fa {
  color: #fff;
}

.ngo-about-header .input-group ::placeholder {
  color: #fff;
  opacity: 1;
  /* Firefox */
}

.ngo-about-header .input-group :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

.ngo-about-header .input-group ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

.ngo-about-header .right-btns {
  padding-top: 20px;
}

.ngo-about-header .right-btns .input-group .btn {
  background-color: #b23d4d;
}

.bg-none {
  background: transparent;
}

.shop-middle .select-row {
  position: sticky;
  top: 53px;
  background-color: #fff;
  padding: 13px 0;
  z-index: 2;
}

.shop-middle .select-row select {
  background: transparent;
  border: 1px solid #bebebe !important;
  width: 80%;
  font-size: 18px;
  color: #484848;
  font-weight: 600;
  padding-left: 5px;
  height: calc(1.6em + 0.75rem + 2px) !important;
}

.shop-middle .btn-primary-shop {
  background-color: #1cafb7;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  padding: 6px 30px;
  border: 2px solid #1cafb7;
}

.shop-middle .btn-primary-shop:hover {
  background-color: transparent;
  color: #1cafb7;
  border: 2px solid #1cafb7;
}

.shop-middle .cart-numbers-notifi {
  width: 20px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  background: #1badc4;
  position: absolute;
  border-radius: 5px;
  font-size: 11px;
  top: -5px;
  right: -5px;
}

.shop-middle .single-prd {
  /* border-right: 1px solid #dee2e6 !important; */
}

.shop-middle .single-prd-row div:nth-child(3) {
  border: 0 !important;
}

.shop-middle .single-prd img {
  border-radius: 5px;
  width: 100%;
  border: 1px solid #ccc;
  height: 210px;
  /* object-fit: none; */
  /* object-fit: contain; */
  object-fit: fill;

}

.shop-middle .single-prd .fa-star {
  color: #b7b7b7;
}

.shop-middle .btn-primary-cart {
  /* background-color: #1cafb7;
  color: #fff !important;
  width: 151px;
  font-weight: 600;
  font-size: 16px;
  padding: 5px 0px;
  border: 2px solid #1cafb7;
  position: relative; */
  /* background-color: #1cafb7;
  color: #fff !important;
  width: 109px;
  font-weight: 600;
  font-size: 11px;
  padding: 3px 0px;
  border: 2px solid #1cafb7;
  position: relative; */
  background-color: #1cafb7;
    color: #fff !important;
    width: 120px;
    font-weight: 600;
    font-size: 13px;
    /* padding: 3px 0px; */
    border: 2px solid #1cafb7;
    position: relative;
}

.shop-middle .btn-primary-cart span {
  margin-left: 15px;
}

.shop-middle .btn-primary-cart:hover {
  background-color: transparent;
  color: #1cafb7 !important;
  border: 2px solid #1cafb7;
}

.shop-middle .btn-primary-cart::before {
  /*content: url(../images/btn-cart.png);*/
  position: absolute;
  left: 0;
  top: 0;
}

.shop-middle .shopBreadcrumbs span {
  color: #696969;
  margin: 0 8px;
}

.shop-middle .shopBreadcrumbs a {
  color: #696969;
  font-size: 14px;
}

.shop-middle .slider-nav img {
  border-radius: 5px;
  border: 1px solid#ccc;
}

.shop-middle .fa {
  margin-right: 3px;
}

.shop-middle .shop-details-right .color-size {
  border: 0 !important;
}

.shop-middle .shop-details-right .quantity-row input {
  width: 68px;
  background: #e5e5e5;
  border: 1px solid #ccc;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
}

.shop-middle .shop-details-right .add,
.shop-middle .shop-details-right .sub {
  width: 23px;
  background-color: #1cafb7;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 0px 0 3px 0;
  border: 0px solid #1cafb7;
  text-align: center;
  border-radius: 50%;
}

.shop-middle .shop-details-right .add,
.shop-middle .shop-details-right .sub:focus {
  outline: 0;
}

.shop-middle .slider-nav .slick-slide {
  transition: all ease-in-out 0.3s;
  opacity: 0.7;
}

.shop-middle .slider-nav .slick-active {
  opacity: 0.8 !important;
}

.shop-middle .slider-nav .slick-current {
  opacity: 1 !important;
}

/* .shop-middle .address-form {
margin: 0 10px;
} */
.shop-middle .address-form .form-control {
  height: calc(1.8em + 0.75rem + 2px);
  border: 1px solid #ccc !important;
}

.shop-middle .address-form label {
  color: #696969;
  font-size: 16px;
  width: 100px;
  text-align: right;
}

.shop-middle .address-form .address-field {
  border: 0 !important;
  border: 1px solid rgb(204, 204, 204) !important;
  border-radius: 0.25rem;
  background: #e5e5e5;
}

.shop-middle .address-form .footer-btns {
  max-width: 433px;
  width: 100%;
  margin-right: 30px;
}

.shop-middle .address-form .btn-save {
  background: transparent;
  border: 1px solid #1cafb7;
  color: #1cafb7;
  font-size: 14px;
}

.shop-middle .address-form .btn-save:hover {
  border: 1px solid #aaa;
  color: #aaa;
}

.shop-middle .top-row {
  position: sticky;
  top: 53px;
  background: #fff;
  z-index: 2;
}

.shop-middle .confirmOrders img {
  width: 70px;
  height: 60px;
  object-fit: cover;
}

.shop-middle .confirmOrders .border-right:nth-child(even) {
  border-right: 0 !important;
}

.myImage {
  width: 100%;
  height: 220px;
  object-fit: cover;
}

.myImage-ngoList-cover {
  /* width: 100%; */
  width: 120%;
  /* height: 180px; */
  height: 120px;
  object-fit: fill;
  
  /* object-fit: contain; */

}

/*.myImage-50 {
 width: 50%;
 height: 220px;
 object-fit: cover;
}*/
.one-img-post .myPostImage-100 {
  width: 100%;
  height: 257px;
  object-fit: cover;
  border: 1px solid #e5e5e5;
}

.two-img-post .myPostImage-100 {
  width: 98%;
  height: 257px;
  object-fit: cover;
}

.three-img-post .myPostImage-100 {
  width: 98%;
  height: 257px;
  object-fit: cover;
}

.three-img-post .myPostImage-50 {
  width: 98%;
  height: 126px;
  object-fit: cover;
}

.four-img-post .myPostImage-50 {
  width: 98%;
  height: 126px;
  object-fit: cover;
}

.text-short-name {
  width: 104px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-short-name2 {
  width: 67%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.connect-btn {
  width: 105px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* .recent-slide-img {
  width: 100%;
  height: 135px;
  object-fit: cover;
} */
.recent-slide-img {
  width: 100%;
  height: 120px;
  object-fit: cover;
}

.no-wrap {
  white-space: nowrap;
}

.faqs-accordian .question {
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.faqs-accordian .question .fa {
  font-size: 20px;
  color: #bebebe;
}

.faqs-accordian .question .fa.fa-minus {
  display: none;
}

.faqs-accordian .column-item.jquery-accordion-active .fa.fa-minus {
  display: block;
}

.faqs-accordian .column-item.jquery-accordion-active .fa.fa-plus {
  display: none;
}

.faqs-accordian .column-item.jquery-accordion-active .question h4 {
  opacity: 1;
}

.faqs-accordian .column-item .content {
  padding: 15px 0 0 0;
  display: none;
}

.faqs-accordian .column-item .content p {
  margin-bottom: 10px;
}

.faqs-accordian h4 {
  padding: 6px 0;
}

.stickyheader {
  top: 111px;
  z-index: 4;
}

.partners-themes h3,
.partners-themes p {
  font-family: "Open Sans", sans-serif;
}

.partners-themes h3 {
  font-weight: 700;
}

.partners-themes h2 {
  font-size: 50px;
  color: #4f4f4f;
  font-family: "Century Gothic";
  font-weight: normal;
}

.partners-themes a {
  color: #484848;
  font-weight: 600;
  padding: 0 17px 10px 0;
  display: inline-block;
}

.partners-themes span {
  padding: 0 17px 0px 0;
}

.our-partners h2 {
  font-size: 50px;
  color: #4f4f4f;
  font-family: "Century Gothic";
  font-weight: normal;
}

.our-partners h3 {
  font-size: 22px;
  color: #f05368;
  font-weight: 700;
}

.our-partners h3 span {
  font-size: 16px;
  color: #4f4f4f;
  display: block;
}

.our-partners.inspiration-row p {
  font-size: 20px;
  line-height: 24px;
}

.our-partners.inspiration-row .commaleft {
  /*content: url(../images/commaleft.png);*/
  position: absolute;
  left: -13px;
  top: -2px;
  width: 23px;
}

.our-partners.inspiration-row .commaright {
  /*content: url(../images/commaright.png);*/
  position: absolute;
  right: 114px;
  bottom: 64px;
  width: 23px;
}

.our-partners.inspiration-row p span {
  color: #f05368;
  font-weight: 700;
}

.our-partners.inspiration-row h3 {
  color: #f05368;
  font-weight: 700;
  font-size: 30px;
}

.right-fix-inner {
  max-width: 290px;
  width: 100%;
  position: fixed;
  padding-top: 0;
}

.side-small-slider {
  /*min-height: 301px;*/
  min-height: 250px;
  clear: both;
  display: block;
}

.smart {
  height: 500px;
  clear: both;
  display: table;
  overflow: auto;
}

.sideHeight {
  height: 400px !important;
}

.select-don-match {
  min-height: 160;
}

/* .side-top-menu {
 min-height: 250px;
 clear: both;
 overflow: auto;
 display: block;
} */
@media (max-width: 1919px) {
  .right-fix-inner {
    padding-top: 5px;
  }

  .postOpen-overlay .comments-col p.comment {
    margin-top: 0;
  }

  .postOpen-overlay .name-col {
    left: -20px;
  }

  .postOpen-overlay .comments-col .name-col {
    left: -20px;
  }

  .postOpen-overlay .comments-col p.comment {
    left: 10px;
  }
}

@media (max-width: 1599px) {
  .postOpen-overlay .name-col {
    left: -10px;
  }

  .postOpen-overlay .comments-col .name-col {
    left: -10px;
  }
}

@media (max-width: 1439px) {
  .select-white.area-interest {
    width: 191px;
  }

  .about-page .partners-slider {
    width: 101%;
  }

  .breadcrumbs {
    max-width: 1008px;
  }

  .postOpen-overlay .slick-slide img {
    height: 500px;
    object-fit: contain;
  }

  .postOpen-overlay .slick-next {
    right: 5%;
  }

  .postOpen-overlay .slick-prev {
    left: 5%;
  }

  .postOpen-overlay .slick-prev,
  .postOpen-overlay .slick-next {
    top: 53%;
  }

  .postOpen-overlay .name-col {
    left: 0px;
  }

  .postOpen-overlay .comments-col .name-col {
    left: 0px;
  }
}

@media (max-height: 817px) {
  .about-page .partners-slider {
    width: auto;
  }

  .side-menu ul li a {
    font-size: 16px;
  }

  .more-menus {
    position: relative;
  }

  .more-menus .hidden-menus {
    position: absolute;
    background: #fff;
    bottom: 36px;
    left: 0;
    right: 0;
    margin: auto;
    padding: 15px 17px 10px 17px;
    display: none;
    width: auto;
    height: auto;
    border-radius: 5px;
    border: 1px solid#ccc;
  }

  .more-menus .hidden-menus li:nth-child(4) {
    border-bottom: 0px solid rgb(229, 229, 229);
  }

  .ngo-menu p {
    margin: 0;
  }

  /* .more-menus .hidden-menus::before {
   border-right: 15px solid transparent;
   border-top: 16px solid #fff;
   bottom: -17px;
   content: "";
   left: 84%;
   margin-left: -30px;
   position: absolute;
   box-shadow: 1px 1px 1px 1px #888888;
   z-index: 0;
   border-left: 15px solid transparent;
} */
  .side-menu .more-menu-open {
    display: block;
  }

  .side-menu .btn-primary,
  .side-menu .btn-primary-outline {
    padding: 7px 30px;
  }
}

@media (max-width: 1439px) {
  .side-menu .footer-small {
    position: static;
  }
  .breadcrumbs{
    width: 60%;
  }
}
@media (max-width: 1440px) {
  .breadcrumbs{
    width: 60%;
  }
}
@media (max-width: 768px) {
  .breadcrumbs{
    width: 60%;
  }
}
@media (max-width: 961) {
  .breadcrumbs{
    width: 60%;
  }
}
@media (max-width: 1200px) {
  .breadcrumbs{
    width: 60%;
  }
}


@media (max-width: 1365px) {
  .our-partners.inspiration-row .commaright {
    right: 60px;
    bottom: 59px;
  }

  .about-page .team-row .anirudh {
    margin-left: -31px;
  }

  .bydate-fields .date-field {
    width: 110px !important;
  }

  .bydate-fields .small-field {
    width: 108px !important;
  }

  .side-menu {
    /* width: 303px; */
    width: 230px;
    max-width: 100%;
  }

  .ngo-top-icons a.active-all {
    width: 79px;
    line-height: 79px;
  }

  .ngo-top-icons a {
    width: 80px;
    line-height: 80px;
  }

  .middle-col .ngo-middle .btn-primary-blue,

  .middle-col .ngo-middle .btn-primary-outline,
  .middle-col .ngo-middle .btn-primary,
  .middle-col .ngo-inner-middle .btn-primary,
  .middle-col .ngo-inner-middle .btn-primary-outline {
    width: 109px;
    padding: 8px 0px;
  }

  .middle-col .ngo-middle .btn-primary-green {
    width: 120px;

    /* padding: 2px 9px; */
  }

  .container.text-right.d-flex.justify-content-end.mx-0.pt-0.align-items-center {
    position: absolute !important;
    top: 80% !important;
    /* bottom: 86%; */
    left: 9% !important;
  }

  .breadcrumbs {
    /* width: 50%; */
    width: 65%;
    max-width: none;
  }

  .ngo-top-icons img {
    width: 79px;
  }

  .side-menu .side-logo {
    width: auto;
  }

  .header .input-group {
    width: 292px;
    /* width: 173px; */
  }

  .middle-col .logo {
    width: 312px;
    position: fixed;
    margin-left: -329px;
    border-bottom: 1px solid #e5e5e5;
    top: 0;
    z-index: 5;
    background: #fff;
  }
}

@media (max-width: 1279px) {
  .our-partners.inspiration-row .commaright {
    right: -30px;
  }
}

@media (max-width: 1272px) {
  .bydate-fields .small-field {
    width: 90px !important;
  }

  /* .bydate-fields .dates{
 margin-bottom: 10px;
}
.bydate-fields .date-field {
 width: 71% !important;
} */
}

@media (max-width: 1279px) {
  .about-page .team-row .imgBox {
    margin: 0;
    top: 0;
    width: max-content;
  }

  .about-page .team-row .imgBox .names {
    position: absolute;
    padding: 5px 20px;
    left: 0;
    top: 95%;
    right: 0;
    margin: auto;
  }

  .about-page .dst-box {
    width: 100%;
  }

  .ngo-top-icons a {
    width: 74px;
    line-height: 74px;
  }

  .side-menu .more-menu-open {
    display: none;
  }

  .more-menus .hidden-menus {
    position: static;
    background: none;
    padding: 0;
    display: block !important;
    width: auto;
    height: auto;
    border: 0;
  }

  .side-menu .side-logo {
    width: 282px;
  }

  .ngo-top-icons a.active-all {
    width: 74px;
  }

  .ngo-top-icons img {
    width: 74px;
  }

  .middle-col .logo img {
    width: 100%;
  }

  .new-here {
    max-width: 270px;
  }

  .header .input-group {
    /* width: 272px; */
    width: 173px;
  }

  .side-menu {
    /* width: 273px; */
    width: 230px;
    height: 100%;
    overflow-y: auto;
    /* position: absolute; */
  }

  .about-side-menu::-webkit-scrollbar,
  .side-menu::-webkit-scrollbar {
    display: none;
  }

  .side-menu-scroll {
    position: fixed;
    margin-top: auto;
    bottom: 0px;
    margin-bottom: 0;
  }

  .side-menu .side-logo {
    width: auto;
  }

  .side-menu .side-logo img {
    max-width: 95%;
    height: auto;
  }
}

@media (max-width: 1199px) {
  .mob-search {
    display: block;
    margin-top: -12px;
  }

  .shop-middle .top-row {
    border-top: 1px solid #ccc;
    top: 127px;
    background: rgb(255, 255, 255);
  }

  .shop-middle .select-row {
    top: 127px;
    margin-bottom: 33px;
    padding: 13px 0px;
    z-index: 2;
    border-top: 1px solid #dee2e6;
  }

  .reviews-fields {
    margin-bottom: 55px;
    top: 129px;
  }

  .bydate-fields .small-field {
    width: auto !important;
  }

  .bydate-fields .dates {
    margin-bottom: 10px;
    width: auto;
  }

  .postOpen-overlay .comments-col p.comment {
    margin-top: 8px;
    left: 0;
  }

  .postOpen-overlay .slick-slide img {
    height: 400px;
    object-fit: contain;
  }

  .postOpen-overlay .h-100vh {
    height: auto;
  }

  .postOpen-overlay {
    overflow: auto;
  }

  .postOpen-overlay .name-col {
    left: 0;
  }

  .postOpen-overlay .comments-col .name-col {
    left: 0;
  }

  .offset-xl-3 {
    margin-left: 0;
  }

  .bydate-fields .date-field {
    width: 71% !important;
  }

  .bydate-fields {
    top: 180px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  .ng-donations-tabs {
    top: 45px;
  }

  .donate-middle-inner {
    margin-top: 130px;
  }

  .volunteer-col {
    margin-top: 40px;
  }

  .btn-volunteer-now:nth-child(1) {
    margin-top: 50px;
  }

  .donate-fix {
    top: 3px;
    padding: 4px 25px !important;
  }

  .donate-middle {
    margin-top: 137px;
  }

  .volunteer-col .small-header {
    margin-bottom: 30px;
    margin-top: 40px;
  }

  .tabs-sticky {
    position: fixed !important;
    top: 120px;
    left: 0px;
    width: 66.6%;
  }

  .rightCol ul.menu-list {
    margin-top: 40px;
  }

  .middle-col .ngo-middle {
    /* padding-top: 204px; */
    padding-top: 67px;
  }
  .breadcrumbs.border-right-0{
    border-bottom: 1px solid #dee2e6 !important;
  }
  .breadcrumbs {
    top: 87px;
    font-size: 19px;
    margin: 0px;
    padding: 6px 0px;
    text-indent: 15px;
    background: #fff;
    width: 100%;
    left: 0;
    border: 0;
  }

  /* .rightCol ul.menu-list {
   margin: 52px 0 0 0;
} */
  .dropdown-row {
    margin-top: 50px;
  }

  .breadcrumbs .right-text {
    padding-right: 15px;
  }

  .ngo-top-icons a.active-all {
    display: inline-table;
  }

  .ngo-top-icons {
    text-align: center;
    margin-top: 32px;
    width: 100%;
  }

  .ngo-top-icons .icons-inner {
    display: block;
  }

  .side-menu .before-login {
    margin-top: 0;
  }

  .side-menu .userbox {
    margin-top: 4px;
  }

  .header .mob-header-icons {
    display: block;
    right: 5px;
  }

  .mob-pd-0 {
    padding: 0;
  }

  .middle-col .header {
    left: 0;
  }

  /* .top-tabs {
   margin-left: 0;
   margin-right: 0;
 } */
  .rightCol .input-group {
    width: 31%;
    top: 74px;
  }

   .toggleBtn {
    display: block;
  }

  .side-menu .menuCloseBtn {
    display: block;
  }

  .side-menu {
    background: #fff;
    z-index: 10;
    margin-top: 0;
    left: 0;
    padding-left: 10px;
    /* width: 283px; */
    width: 230px;
  }

  .side-menu .side-logo {
    display: block;
    margin-top: 34px;
    width: 270px;
    position: relative;
  }

  .menu-open {
    left: 0;
    transition: all 0.3s;
  }

  .menu-close {
    left: -300px;
  }

  .middle-col .logo {
    position: relative;
    margin-left: 0;
  }

  .middle-col {
    padding-bottom: 80px;
    margin-left: 0;
  }

  .middle-col .top-tabs {
    position: relative;
    margin-left: 0;
  }

  .middle-col .input-group {
    /* display: none; */
    display: inline-flex;
    
  }

  .header {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
  }

  .middle-col .logo {
    width: 230px;
    border: 0;
    margin-left: 45px;
    display: block;
    padding: 15px 0;
  }

  .middle-col .logo img {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .bydate-fields .date-field {
    width: 100% !important;
  }

  .bydate-fields .dates .date-field {
    width: 160px !important;
  }

  .registration-steps.form-box .date-field {
    width: inherit !important;
  }

  .medium-btn {
    padding: 6px 13px;
  }

  .ngo-top-icons a {
    width: 54px;
    line-height: 54px;
  }

  a.dropdown-item {
    padding: 6px 10px !important;
    font-size: 14px !important;
  }

  .ngo-top-icons a.active-all {
    width: 54px;
    line-height: 54px;
  }

  .ngo-top-icons a img {
    width: 35px;
    height: 35px;
  }

  .ngo-top-icons a {
    margin: 5px;
  }

  .people_right .btn-primary-outline {
    margin-top: 10px;
  }
}

@media (max-width: 950px) {
  .new-here {
    margin-right: 22px;
  }

  #rightBottom {
    max-width: 200px;
  }

  .rightCol .volunteer-requir .btn-secondary {
    margin-bottom: 5px;
  }

  /* .rightCol ul.menu-list li a {
    font-size: 14px;
  }
  .rightCol ul.menu-list li a img {
    margin-right: 9px;
    width: 20px;
  } */
}

@media (max-width: 896px) {
  .middle-col .post-icons select {
    width: 147px;
  }

  .side-menu .btn-primary,
  .side-menu .btn-primary-outline {
    padding: 10px 0;
  }

  .middle-col .ngo-middle .btn-primary-blue,
  .middle-col .ngo-middle .btn-primary-green,
  .middle-col .ngo-middle .btn-primary-outline,
  .middle-col .ngo-middle .btn-primary,
  .middle-col .ngo-middle .btn-primary-purplle {
    width: 85px;
    padding: 5px 0px;
    font-size: 13px;
  }

  .rightCol .input-group {
    top: 11px;
  }

  svg {
    /* width: 120%;
    height: auto; */
    /* width: 80px;
    height: 66px; */
  }
}

@media (max-width: 825px) {

  .footer .btn-primary,
  .footer .btn-secondary {
    padding: 6px 20px;
    margin-left: 3px;
  }

  .footer .btn-primary,
  .footer .btn-secondary {
    padding: 6px 0;
    width: 80px;
  }
}

@media (max-width: 768px) {}

@media (max-width: 767px) {
  .breadcrumbs.ngo-breadcrumbs h3 {
    line-height: normal;
    padding: 10px 0;
  }

  .bydate-fields.reviews-dates-select {
    top: 110px;
  }

  /* .rightCol ul.menu-list li {
    padding: 0px 5px;
} */
  .rightCol ul.menu-list {
    margin-top: 0px;
  }

  .right-btn-close,
  .right-btn-open {
    display: block;
  }

  .btn-volunteer-now:nth-child(1) {
    margin-top: 10px;
  }

  .footer .btn-primary,
  .footer .btn-secondary {
    margin-bottom: 5px;
  }

  .about-side-menu {
    top: 0px;
    padding-left: 0;
    background: #fff;
    left: -320px;
    transition: all 0.3s;
  }

  .about-side-menuOpen {
    left: 0;
  }

  .about-side-menu ul li span {
    display: inline-block;
    margin-left: 10px;
  }

  .about-side-menu ul li {
    border-bottom: 1px solid rgb(229, 229, 229);
    padding: 8px 0px;
  }

  .about-side-menu .bottom-icons {
    position: static;
    text-align: center;
    padding: 10px 0;
  }

  .about-side-menu .bottom-icons img {
    margin: 0 10px;
  }

  .about-side-menu {
    padding-right: 0;
  }

  .ngo-about-header .toggle {
    position: absolute;
    left: 0px;
  }

  .ngo-about-header .logo {
    margin: auto;
    display: block;
  }

  .shop-middle {
    margin-top: 36px;
  }

  .shop-middle .single-prd {
    border-right: 0;
  }

  .shop-middle .select-row {
    display: none;
  }

  .reviews-fields {
    display: none;
  }

  .reviews-fields {
    top: 121px;
    border-top: 0;
  }

  .bydate-fields {
    display: none;
  }

  .bydate-fields .dates {
    width: 93%;
    text-align: center;
    margin: 5px auto;
  }

  .bydate-fields .search-row {
    width: 100%;
    margin: 10px 0;
  }

  .bydate-fields .dates .date-field {
    width: 100% !important;
  }

  .bydate-fields .small-field {
    width: 35% !important;
  }

  .postOpen-overlay .postModal-slider {
    padding-top: 0;
  }

  .postOpen-overlay .slick-prev::before,
  .postOpen-overlay .slick-next::before {
    font-size: 20px;
  }

  .postOpen-overlay .slick-slide img {
    height: 400px;
    object-fit: contain;
  }

  #selectVolunteerModal2 .date-field,
  #comProfileModal .date-field,
  #editDetailsModal .date-field {
    width: 100% !important;
  }

  #editDetailsModal .form-control,
  #comProfileModal .form-control {
    width: 100%;
    height: calc(1.8em + 0.75rem + 2px);
  }

  #selectVolunteerModal2 .form-control {
    width: 100% !important;
    max-width: none !important;
  }

  .donate-middle {
    margin-top: 211px;
  }

  ul.mobilemenu-list {
    display: block;
  }

  .breadcrumbs h3 {
    line-height: 40px;
  }

  .dropdown-menu {
    margin-left: -138px;
  }

  .mob-dropdowns .dropdown-menu {
    margin-left: 0;
  }

  .breadcrumbs {
    top: 67px;
    border-top: 1px solid rgb(204, 204, 204);
    padding: 0;
  }

  .breadcrumbs .arrow-mob-btn,
  .breadcrumbs .arrow-mob-btn2 {
    display: block;
  }

  .ngo-top-icons {
    margin: 40px auto auto;
    left: 0;
  }

  /* .ngo-top-icons .select-category {
    display: none;
  } */
  .modal-content {
    border: 0.5px solid #f0536a;
    border-radius: 0;
  }

  .modal-dialog {
    margin: auto;
  }

  .breadcrumbs {
    width: 100%;
    border-bottom: 1px solid #dee2e6 !important;
    z-index: 6;
  }

  .ngo-middle .border-right {
    border: 0 !important;
  }

  .ngo-middle .ngoPost-box {
    padding: 0;
  }

  .ngo-middle .ngoPost-box .size18 {
    font-size: 13px;
  }

  .middle-col .ngo-middle {
    /* padding: 120px 0 0 0; */
    padding: 45px 0 0 0;
  }

  .ngo-top-icons select {
    font-size: 15px;
    margin: 5px auto;
  }

  .footer .mobile-icon-footer {
    display: block;
  }

  .footer {
    padding-top: 10px;
  }

  .rightCol {
    background-color: #fff;
    position: fixed;
    right: -300px;
    top: 0;
    overflow: auto;
    z-index: 15;
    width: 300px;
    height: 100%;
    transition: all 0.5s;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  #rightBottom {
    width: 100% !important;
    max-width: none;
  }

  .right-btn-open {
    position: fixed;
    right: 0;
    top: 140px;
    z-index: 10;
    width: 28px;
  }

  .right-open {
    right: 0 !important;
  }

  .img-fluid {
    width: 100%;
  }

  .products-slider .btn-buy {
    padding: 0.375rem 1.5rem;
  }

  .footer h3 {
    font-size: 17px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  .tabs-sticky {
    top: 104px;
    width: 100%;
  }
}

@media (max-width: 745px) {
  .middle-col .ngo-middle {
    /* padding: 189px 0 0 0; */
    padding: 45px 0 0 0;
  }

  .our-partners.inspiration-row .commaleft {
    left: 0px;
    top: -45px;
  }

  .our-partners.inspiration-row .commaright {
    right: 0px;
    bottom: 05px;
  }
}

@media (max-width: 640px) {
  .postModalSlider .slick-slide img {
    height: auto;
  }

  .ngofullname {
    margin-left: -12px;
    max-width: 190px;
  }

  .btn-secondary {
    padding: 4px 0;
  }

  .middle-col .ngo-middle .user-profile-img {
    top: 10px;
    margin-left: 0;
    width: 60px;
    top: -3px;
    height: 66px;
  }

  .myImage {
    height: 130px;
  }

  .select-white.area-interest {
    width: 155px;
  }

  .btn-primary-green,
  .btn-secondary {
    font-size: 13px;
  }

  .one-img-post .myPostImage-100 {
    height: 125px;
  }

  .two-img-post .myPostImage-100 {
    height: 127px;
  }

  .btn-primary-purplle {
    padding: 5px 0;
    width: 100px;
  }

  .btn-primary-blue {
    padding: 5px 0;
    width: 100px;
  }

  .timelineSlider .slick-slide img {
    height: auto;
  }

  .our-partners h2 {
    font-size: 35px;
  }

  .partners-themes h2 {
    font-size: 35px;
  }

  .partners-themes a {
    padding: 4px 0;
    display: block;
    font-size: 14px;
    border-bottom: 1px solid #ccc;
    margin: 0;
  }

  .partners-themes span {
    display: none;
  }

  .stickyheader {
    top: 86px;
  }

  .how-itworks-video iframe {
    width: 100%;
    height: 200px;
  }

  #hex {
    transform: scale(0.7);
  }

  .about-page .team-row .imgBox .names {
    padding: 5px 10px;
    left: -20px;
  }

  .about-page .team-row .imgBox {
    width: 45%;
    margin: 5px;
  }

  .about-page .team-row .imgBox img {
    width: 100%;
  }

  .about-page .team-row .imgBox .names h2 {
    font-size: 15px;
  }

  .about-page .team-row .imgBox .names h2 span {
    font-size: 12px;
  }

  .about-page .partners-slider {
    margin-left: 0;
  }

  .about-page.partners-row .slick-slide {
    margin: 0 10px;
  }

  .about-page .partners-slider {
    padding: 30px 0px;
  }

  .about-page.ourThemes .icon-box {
    margin: 0 0px 15px 0px;
    width: 31%;
  }

  .about-page.ourThemes .icon-box h2 {
    font-size: 13px;
    margin: 4px 0px 0px;
  }

  .about-page.ourThemes .icon-box img {
    width: 100%;
  }

  .about-page .dst-box .text-box {
    padding: 20px 10px;
    width: 98%;
  }

  .about-page h3 {
    font-size: 35px;
    line-height: 38px;
  }

  .about-page h3 span {
    font-size: 25px;
    line-height: 32px;
  }

  .about-page .btn-primary {
    font-size: 13px;
    padding: 6px 18px;
  }

  .ngo-about-header .logo {
    width: 200px;
  }

  .ngo-about-header .btn-dark {
    padding: 3px 19px;
    font-size: 13px;
  }

  .ngo-about-header .btn-white {
    padding: 3px 19px;
    font-size: 13px;
  }

  .shop-middle .top-row {
    border-top: 0px solid rgb(204, 204, 204);
    top: 109px;
    background: rgb(255, 255, 255);
  }

  .shop-middle .confirmOrders .border-right {
    border-right: 0 !important;
  }

  .shop-middle .address-form .w-75 {
    width: 100% !important;
  }

  .shop-middle .address-form label {
    text-align: left;
    width: 100%;
  }

  .registration-steps.select-theme-type .icons-row .icons-name {
    line-height: 15px;
    display: block;
    top: 102px;
  }

  .registration-steps.select-theme-type .icons-row a {
    margin: 5px 7px 40px 5px;
  }

  .registration-steps-numbers hr {
    width: 50px;
  }

  .registration-steps-numbers .number {
    width: 35px;
    line-height: 35px;
    font-size: 19px;
  }

  .registration-steps.form-box .btn-save {
    padding: 0.375rem 0.3rem;
  }

  .donate-middle-inner {
    margin-top: 20px;
  }

  .ng-donations-tabs {
    top: 41px;
  }

  .bydate-fields {
    top: 158px;
  }

  .middle-col .post-icons select {
    padding: 0px 0.75rem;
    width: auto;
    height: calc(1em + 0.75rem + 2px);
    margin-top: 4px;
  }

  .donate-fix {
    padding: 4px 0 !important;
    right: 4px;
    width: 80px !important;
  }

  #selectThemeModal .icons-name {
    display: block;
    line-height: 14px;
  }

  #selectThemeModal a {
    width: 85px;
    line-height: 85px;
    margin: 5px 3px 35px 3px;
  }

  .modal .btn-primary,
  .modal .btn-primary-outline {
    padding: 6px 26px;
  }

  .donate-middle {
    margin-top: 34px;
  }

  #otpModal .form-control {
    width: 40px;
    margin: 0 2px;
  }

  /* .profile-page .profile-thumb {
    top: -38px;
    width: 27%;
  } */
  .colorful-icons a {
    margin: 3px;
  }

  .profile-page .profile-thumb img {
    width: 100%;
  }

  .like-share-row .dropdown {
    top: 0 !important;
  }

  .like-share-row .dropdown-menu {
    margin-left: -97px;
    margin-top: 5px;
  }

  .mob-font10 {
    font-size: 10px !important;
  }

  .mob-font11 {
    font-size: 11px !important;
  }

  .mob-font12 {
    font-size: 12px !important;
  }

  .mob-font13 {
    font-size: 13px !important;
  }

  .mob-font14 {
    font-size: 14px !important;
  }

  .mob-font15 {
    font-size: 15px !important;
  }

  .mob-font16 {
    font-size: 16px !important;
  }

  .mob-font17 {
    font-size: 17px !important;
  }

  .mob-font18 {
    font-size: 18px !important;
  }

  .mob-font22 {
    font-size: 22px !important;
  }

  .mob-mt-3 {
    margin-top: 3px !important;
  }

  .middle-col .ngo-middle {
    padding: 0px 0px 0px;
    margin-top: 38px;
  }

  .middle-col .ngo-middle-mob-margin {
    padding: 0px 0px 0px;
    margin-top: 270px;
  }

  .middle-col .donate-middle-mob-margin {
    margin-top: 150px;
  }

  .breadcrumbs .filter-mob-btn {
    display: block;
    margin-right: 15px;
    margin-top: 9px;
  }

  .ngo-top-icons {
    display: none;
  }

  .ngo-top-icons {
    padding: 5px 0px;
    position: fixed;
    z-index: 5;
    background-color: rgb(255, 255, 255);
    top: 70px;
    border: 1px solid rgb(240, 83, 104) !important;
    width: 100%;
  }

  .ngo-top-icons .icons-inner {
    border: 0 !important;
  }

  .ngo-top-icons a {
    width: 58px;
    line-height: 58px;
  }

  .ngo-top-icons a img {
    width: 35px;
    height: 35px;
  }

  .ngo-top-icons .all-icon {
    display: none !important;
  }

  .ngo-top-icons a {
    margin: 3px;
  }

  .middle-col .ngo-middle .ngo-profile-img {
    top: 10px;
    margin-left: 0;
    width: 60px;
    /* transform: scale(.8); */
    height: 66px;
  }

  .tabs-sticky {
    top: 104px;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .tabs-scroll {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .px-mob {
    padding-left: 0;
    padding-right: 0;
  }

  .middle-col .logo {
    width: 140px;
  }

  .pt-sm-6 {
    padding-top: 60px;
  }

  .dropdown-row {
    margin-top: 68px;
  }

  .breadcrumbs .right-text {
    padding-right: 6px;
  }

  .breadcrumbs .size16 {
    font-size: 13px !important;
    padding-top: 11px;
  }

  .profile-page .size16 {
    font-size: 15px !important;
  }

  .profile-page .top-tabs {
    /* position: absolute; */
    left: 0;
    width: 100%;
  }

  .profile-page .size18 {
    font-size: 13px !important;
  }

  /* .ngo-inner-middle .top-tabs {
    position: absolute;
    left: 0;
    width: 100%;
  } */
  .modal .share-box {
    padding: 10px;
  }

  .modal .share-box img {
    width: 60%;
  }

  .modal .share-box .fa {
    width: 35px;
    line-height: 35px;
    text-align: center;
    font-size: 14px;
  }

  .middle-col .ngo-middle .btn-primary-blue,
  .middle-col .ngo-middle .btn-primary-green,
  .middle-col .ngo-middle .btn-primary-outline,
  .middle-col .ngo-middle .btn-primary,
  .middle-col .ngo-inner-middle .btn-primary,
  .middle-col .ngo-inner-middle .btn-primary-outline,
  .middle-col .ngo-middle .btn-primary-purplle,
  .ngo-inner-middle .btn-primary-purplle,
  .ngo-inner-middle .btn-primary-blue,
  .ngo-inner-middle .btn-primary-green,
  .medium-btn,
  .volunteer-col .btn-primary-green,
  .donate-middle-inner .btn-primary-green,
  .donate-middle-inner .btn-primary-purplle,
  .middle-col .donate-middle-inner .btn-primary-outline,
  .middle-col .donate-middle-inner .btn-primary {
    width: 90px;
    padding: 4px 0px;
    font-size: 13px;
  }

  .btn-yellow {
    font-size: 13px;
    width: 91px;
  }

  .volunteer-col .btn-secondary {
    width: 120px;
    padding: 4px 0px;
    font-size: 13px;
  }

  .ngo-inner-middle .profile-thumb {
    position: absolute;
    top: -29px;
    left: 4px;
    width: 70px;
  }

  .donate-middle-inner .profile-thumb {
    position: absolute;
    top: -5px;
    left: 4px;
    width: 70px;
  }

  .donate-middle-inner .profile-thumb img,
  .ngo-inner-middle .profile-thumb img {
    width: 100%;
  }

  .side-menu {
    padding-bottom: 10px;
  }

  .footer .mobile-icon-footer img {
    margin: 0 6px;
  }

  .footer .btn-secondary,
  .footer .btn-primary {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .footer .mobile-icon-footer .btn-primary {
    margin-bottom: 0;
    margin-top: 0;
    padding: 11px 20px;
    font-size: 14px;
  }

  .footer h3 span {
    font-size: 13px;
    display: block;
  }

  .modal-sm {
    max-width: none;
  }

  .modal .post-icons img {
    width: 25px;
  }

  .middle-col .post-icons .btn-primary {
    font-size: 13px;
    padding: 2px 21px;
    border-radius: 5px;
    height: 28px;
    margin-top: 3px;
  }

  .modal .createPost {
    border-radius: 3px;
    padding: 8px 9px;
    font-size: 14px;
  }

  /* .middle-col .dropdown {
   top: -19px;
 } */
  .middle-col .createPost {
    border-radius: 3px;
    padding: 8px 9px;
    font-size: 14px;
  }

  .middle-col .dropdown .fa-ellipsis-h {
    font-size: 22px;
  }

  .middle-col .input-group {
    display: inline-flex;
    /* width: 107px; */
    width: 152px;
    margin-top: 3px;
  }

  .btn-primary-cart-new{
    /* width: 55px !important; */
    width: 78px !important;
    margin-top: 6px !important;
    padding: 3px !important;
    font-size: 11px !important;
  }
  .form-control {
    font-size: 12px;
}

  .header {
    border-bottom: 0px solid #ccc;
  }

  .middle-col .top-tabs a {
    font-size: 13px;
  }

  .middle-col .post-icons img {
    width: 25px;
  }

  .middle-col .post-icons .btn-primary {
    font-size: 13px;
    padding: 2px 21px;
    border-radius: 5px;
  }

  .middle-col .post-icons {
    position: absolute;
    right: 21px;
    bottom: 10px;
  }

  .ngoPost-box .size18 {
    font-size: 13px !important;
    /* text-align: center; */
  }

  .ngoPost-box .size16 {
    font-size: 15px !important;
  }

  .ngo-inner-middle .size16 {
    font-size: 15px !important;
  }

  /* .ngo-inner-middle .size18 {
   font-size: 13px !important;
 } */
  .ngoPost-box .btn-link {
    display: block;
    text-decoration: underline;
    padding: 10px 0 0 0;
  }

  .middle-col .top-tabs {
    overflow-x: auto;
    margin-right: 0;
    width: -webkit-fill-available;
    /* margin-left: -15px ;
    margin-right: -15px; */
  }
}

@media (max-width: 450px) {
  .volunteer-col .btn-secondary {
    width: 114px;
  }

  .sharedPostMobile svg {
    width: 50px;
    height: auto;
  }

  .middle-col .top-tabs {
    overflow-x: auto;
    margin-right: 0;
    width: -webkit-fill-available;
  }

  .middle-col .top-tabs {
    overflow-x: auto;
    margin-right: 0;
    width: -webkit-fill-available;
    /* margin-left: -15px ;
    margin-right: -15px; */
  }

  .tabs-sticky {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .side-menu ul li a {
    font-size: 16px;
  }

  .middle-col .top-tabs::-webkit-scrollbar {
    display: none;
  }

  .top-tabs-expand {
    min-height: 160px !important;
  }

  .mob-dropdowns .dropdown-menu {
    top: 30px !important;
  }

  .middle-col .top-tabs .inner {
    width: 100%;
  }

  .middle-col .top-tabs a {
    height: 38px;
    white-space: nowrap;
    padding: 10px 15px;
  }

  .middle-col .top-tabs a.active {
    min-width: 42px;
  }

  #selectThemeModal .icons-name {
    top: 90px;
  }
}

@media (max-width: 359px) {

  .btn-primary-green,
  .btn-secondary {
    width: 108px;
  }

  .mob-search {
    width: 110px;
    margin-top: -2px;
  }

  .reviews-fields {
    top: 109px;
  }

  .bydate-fields {
    top: 149px;
  }

  #selectThemeModal a {
    width: 75px;
    line-height: 75px;
    margin: 5px 3px 35px;
  }

  .middle-col .post-icons a {
    margin: 0px 1px;
  }

  .middle-col .post-icons .btn-primary {
    padding: 2px 18px;
  }

  #selectThemeModal .icons-name {
    top: 80px;
  }

  .ngo-top-icons {
    top: 69px;
  }

  .ngo-top-icons a {
    width: 53px;
    line-height: 53px;
    margin: 2px;
  }

  /* .middle-col .logo {
    width: 179px;
  } */
  .header .toggleBtn {
    top: 29px;
  }

  .footer .btn-primary,
  .footer .btn-secondary {
    font-size: 12px;
  }

  .breadcrumbs {
    top: 67px;
  }

  .header .mob-header-icons {
    top: 21px;
  }

  /* .ngo-inner-middle {
   margin-top: 20px;
 } */
  .donate-middle-inner .top-cover,
  .ngo-inner-middle .top-cover {
    margin-top: 25px;
  }

  .tabs-sticky {
    top: 93px;
  }

  ul.mobilemenu-list li a {
    font-size: 12px;
    color: #434343;
  }

  ul.mobilemenu-list li a img {
    color: rgb(67, 67, 67);
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
    width: 17px;
  }

  .footer .mobile-icon-footer img {
    width: 18px;
  }

  .donate-middle .dropdown {
    right: 8px;
    position: absolute;
    cursor: pointer;
    top: -12px;
  }
}

/* THEME OVER CSS */
.active-all {
  background-color: #ae5ea2 !important;
}

.theme-icon-1:hover {
  background-color: #f3e37b !important;
}

.theme-icon-2:hover {
  background-color: #73ccdf !important;
}

.theme-icon-3:hover {
  background-color: #c4da56 !important;
}

.theme-icon-4:hover {
  background-color: #70bbe8 !important;
}

.theme-icon-5:hover {
  background-color: #dce575 !important;
}

.theme-icon-6:hover {
  background-color: #aece41 !important;
}

.theme-icon-7:hover {
  background-color: #f2748d !important;
}

.theme-icon-8:hover {
  background-color: #5090c9 !important;
}

.theme-icon-9:hover {
  background-color: #1aafbd !important;
}

.theme-icon-10:hover {
  background-color: #f6a4b1 !important;
}

.word-break {
  word-break: break-word;
}

#CatpopupModal .iconbox {
  display: inline-block;
  vertical-align: top;
  padding-bottom: 15px;
}

#CatpopupModal .theme-icon {
  margin: 0 3px;
  width: 75px;
  line-height: 75px;
  text-align: center;
  background-color: #bebebe;
  font-size: 18px;
  color: #fff;
  border-radius: 6px;
  transition: all 0.3s;
  display: inline-block;
  margin: 5px 3px;
  position: relative;
}

#CatpopupModal img {
  width: 40px;
  height: 40px;
}

#CatpopupModal .custom-checkbox {
  margin: auto;
  width: max-content;
  margin-top: -10px;
}

#CatpopupModal .icons-name {
  color: #484848;
  z-index: 5;
  top: 60px;
  left: 0;
  right: 0;
  font-size: 13px;
}

#CatpopupModal .custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #aaa;
  background-color: #aaa;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  /*background: #fff url('../images/ajax-loader.gif') center center no-repeat;*/
}

/* Icons */
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"),
    url("../fonts/slick.woff") format("woff"),
    url("../fonts/slick.ttf") format("truetype"),
    url("../fonts/slick.svg#slick") format("svg");
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
   color: transparent;
   outline: none;
   background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  /* margin-bottom: 30px; */
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

.BarData .RCP svg {
  height: 45px !important;
}

.ProgressBarData .RCP svg {
  height: 45px !important;
}

.Doughnutdata canvas {
  height: 220px !important;
}

.btn-primary-cart-new {
  /* background-color: #1cafb7; */
  background-color: #f05368 !important;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  /* font-size: 15px; */
  /* padding: 7px 14px 6px 7px; */
  /* padding: 5px 19px; */
  /* border: 2px solid #1cafb7; */
  border: 2px solid #f05368 !important;
  position: relative;
  width: 120px;
}

.btn-primary-cart-new span {
  margin-left: 15px;
}

.btn-primary-cart-new:hover {
  color: #f05368 !important;
  background-color: white !important;
  border: 2px solid #1cafb7;
}

.btn-primary-cart-new::before {
  /*content: url(../images/btn-cart.png);*/
  position: absolute;
  left: 0;
  top: 0;
}

button.btn.subbtn {
  background-color: #f05368;
  border-color: #f05368;
  color: white;
  padding: 4px 15px;
  font-size: 14px;
}

button.btn.subbtn:hover {
  background-color: white;
  border-color: #f05368;
  color: white;
}

.row-for-lead-nav {
  /* margin-top: 87px; */
  margin-top: 109px;
  margin-left: -23px;
  width: 100%;
  height: 60px;
}

.row-for-lead-nav ul {
  text-decoration: none;

  display: inline-flex;
}

.row-for-lead-nav ul li {
  text-decoration: none;
  display: inline;
  /* padding: 14px; */
padding: 14px 7px;
  /* margin-right: 13px; */
  margin-right: 2px;
}

.row-for-lead-nav ul li a,
.row-for-lead-nav ul li button {
  /* text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: 140px;
  height: 53px;
  color: black;
  text-align: left;
  border-style: solid;
  border-width: 2px;
  border-color: black;
  padding-left: 5px;
  border-radius: 5px;
  border-left-width: 5px;
  border-top: solid;
  border-top-width: 1px;
  box-shadow: 0px 4px #e0e0e0; */
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  /* width: 122px; */
  /* width: 85px;
  height: 53px; */
  width: 105px;
  height: 60px;
  color: black;
  text-align: left;
  border-style: solid;
  border-width: 2px;
  border-color: black;
  padding-left: 5px;
  border-radius: 5px;
  border-left-width: 5px;
  border-top: solid;
  border-top-width: 1px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  /* box-shadow: 1px 3px 12px -1px rgb(0 0 0 / 25%); */
}

.row-for-lead-nav ul li button.active,
.row-for-lead-nav ul li a.active,
.row-for-lead-nav ul li button:hover,
.row-for-lead-nav ul li a:hover {
  border-top-width: 3px;
  border-bottom-width: 3px;
  border-right-width: 3px;
}

.pagination1 .show a.show-page.active {
  text-decoration: underline;
}

.show a.show-page.active {
  text-decoration: underline;
}


.red-btn {
  border-color: red !important;
}

.blue-btn {
  border-color: blue !important;
}

.green-btn {
  border-color: green !important;
}

.yellow-btn {
  border-color: #ffcc00 !important;
}

.purple-btn {
  border-color: #9933ff !important;
}

span.btn-no {
  float: right;
  margin-top: 10px;
  margin-right: 2px;
}

.orange-btn {
  border-color: orange !important;
}

/* .leadlist-nav-btn {
    text-decoration: none;
   font-size: 10px;
   width:100%;
   color:black;
   text-align: center;
   border-style: solid;
   border-width: 2px;
   border-color:black;
} */

input.search-text {
  border-radius: 5px;
  box-shadow: 0px 4px #e0e0e0;
}

span.check {
  display: inline-flex;
}

span.check.pl {
  padding-left: 12px;
}

.my-custom-scrollbar {
  /* position: relative;
  height: 700px;
  overflow: auto; */
  position: relative;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.my-custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
  background-color: #f1f1f1;
  
}
.my-custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #BEBEBE;
}
.agentScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 14px;
  background-color: #f1f1f1;
  border-color: #BEBEBE;
}
.agentScroll::-webkit-scrollbar-thumb {
  background-color: #BEBEBE;
  border-radius: 14px;
}

/* .leadlisttbl::-webkit-scrollbar {
 
  border-radius: 10px;
} */


.table-wrapper-scroll-y {
  display: block;
}

.hr-sty {
  margin-top: 3px;
  margin-bottom: 3px;
  border: 1px solid red;
}

.chkbx {
  display: inline-table;
  margin-top: 35px;
  /* border: 1px solid #b4acac; */
  border: none !important;
}

.drp-st {
  border: none;
  border-left: 2px solid #bbc2c8;
  margin-left: 1px;
}

i.fa.fa-star-o {
  font-size: 18px;
}

.select-filter {
  padding: 6px;
  border-radius: 5px;
  box-shadow: 0px 4px #e0e0e0;
  color: #ababab;
  font-size: 14px;
}

input.search-text {
  border-radius: 5px;
  width: 150px;
  box-shadow: 0px 4px #e0e0e0;
}

.breadcrumbs.left-border-breadcrumbs.fixed-hed.leadlist-header.breadcrums-lead {
  z-index: 5 !important;
}

.date-pos {
  padding-bottom: 16px;
  padding-top: 13px;
  width: 110px;
  font-size: 14px;
  border-radius: 5px;
  text-align: center;
  position: relative;
  /* color: #ababab; */
  display: grid;
  z-index: 1
}

.fs-icon {
  font-size: 25px;
  margin-top: 11px;
}

.color-red {
  color: red;
}

a.btn.btn-primary-purplle-prod {
  /* background: #ae5ea2;
  color: #fff !important;
  font-size: 17px;
  font-weight: 600;
  padding: 5px 0;
  border: 2px solid #ae5ea2 !important;
  border-radius: 7px;
  width: 100px;*/
  /* background: #ae5ea2;
    color: #fff !important;
    font-size: 11px;
    font-weight: 600;
    padding: 3px 0;
    border: 2px solid #ae5ea2 !important;
    border-radius: 4px;
    width: 94px; */
    background: #ae5ea2;
    color: #fff !important;
    font-size: 13px;
    font-weight: 600;
    /* padding: 3px 0; */
    border: 2px solid #ae5ea2 !important;
    border-radius: 4px;
    width: 120px;
} 

.padd-fir {
  padding-right: 222px !important;
}

.padd-sec {
  padding-right: 309px !important;
}

.font-sz {
  font-size: 25px;
}

.dropdown-menu.dropdown-menu-right.style-cd.show {
  border: 1px solid black;
  position: absolute;
  transform: translate3d(-148px, 27px, 0px);
  top: 0px;
  left: 50px !important;
  will-change: transform;
}

.middle-col .dropdown {
  /* position: absolute!important; */
  cursor: pointer !important;
  /* left: 62px!important; */
  color: black !important;
  /* margin-top: -30px; */
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"] {
  right: auto;
  bottom: auto;
}

.action-icon {
  color: #777;
  font-size: 18px;
  display: inline-block;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}

.dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  transform-origin: left top 0;
  box-shadow: inherit;
  background-color: #fff;
  z-index: 1000;
  float: left;
  margin: 0.125rem 0 0;
  color: #212529;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
}

.style-cd {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(-59px, -93px, 0px);
}

.table .dropdown-menu .dropdown-item {
  padding: 5px 10px;
}

.dropdown-toggle::after {
  display: inline-block;
}

.mrgn-tp {
  margin-top: -12px;
}

.fa-ellipsis-v {
  color: #000;
}

/* .dropdown.dropdown-action.drpdn{
  position: absolute!important;
  cursor: pointer!important;
  left: 86px!important;
  color: black!important;
} */

/* samiksha popup css */

#Add-User-modal {
  position: fixed;
  /* Stay in place */
  z-index: 13;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.Add-User-modal-content {
  background-color: #fefefe;
  margin: auto;
  margin-top: 30px;
  padding: 16px 0px;
  border: 1px solid #f05368;
  width: 39%;
  border-radius: 5px;
}

.Add-User-modal-content .content-center {
  display: flex;
  justify-content: center;
}

#Add-User-modal .head {
  margin-left: 78px;
  font-size: 20px;
}

#Add-User-modal .inp-txt {
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

#Add-User-modal select {
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

#Add-User-modal textarea {
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

#Add-User-modal .file-field {
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

#Add-User-modal .title {
  font-weight: bold;
  padding-left: 5px;
}

#Add-User-modal span.title {
  padding-left: 9px;
  /* padding-top: 73px; */
}

#Add-User-modal button.btn-close {
  border-style: none;
  background: none;
  float: right;
}

.close-ic {
  margin-top: -17px;
  margin-right: 4px;
}

#Add-User-modal .Add-User-modal-btn {
  float: right;
  padding-top: 30px;
}

#Add-User-modal .Add-User-modal-btn .btn {
  border-radius: 6px;
  background-color: #f05368;
  border-color: #f05368;
  color: white;
  box-shadow: none;
  padding: 5px 15px;
  font-size: 14px;
}

.radiobtn {
  display: flex;
  padding-left: 11px;
}

.radiobtn label {
  padding-left: 4px;
}

.file-inp {
  display: none;
}

.txt-center {
  text-align: center;
}

.color-g {
  border: none !important;
  background-color: #aece41;
}

.color-g:hover {
  border: 1px solid #aece41 !important;
  color: #aece41 !important;
  /* background-color: #AECE41; */
}

.w-25 {
  max-width: 25%;
}

.pb-4,
.py-4 {
  padding-bottom: 3.5rem !important;
}

.pl-3,
.px-3 {
  padding-left: 2rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.delete-btn {
  background-color: #aece41;
  border-color: #aece41 !important;
  color: white;
  border-radius: 3px;
}

.padding-5 {
  padding: 8px;
  margin-top: 16px;
}

.profile {
  height: 300px;
}

button.btn.minbtn {
  /* background-color: #aece41;
  border-color: #aece41 !important;
  color: white;
  padding: 4px 9px;

  font-size: 14px; */
  background-color: #aece41;
  border-color: #aece41 !important;
  color: white;
  /* padding: 4px 9px; */
  font-size: 13px;
  width: 120px;
  font-weight: 600;
}

button.btn.minbtn:hover {
  background-color: #aece41;
  border-color: #aece41 !important;
  color: white;
}

.ml-30 {
  margin-left: 30px;
}

.display-right {
  position: absolute;
  margin: auto;
  right: 32px;
}

.delete-btn:hover {
  color: #aece41 !important;
  border-color: #aece41 !important;
}

.button.btn.btn-primary.delete-btn {
  padding: 6px 27px;
}

.display-del-btn {
  position: absolute;
  margin: auto;
  right: 169px;
}

.modal .createPost {
  border: 1px solid #d3d3d3;
  background: #e9ecef;
  border-radius: 6px;
  /* margin-bottom: 10px; */
}

.shadow1 {
  box-shadow: 0px 3px 3px rgba(197, 192, 192, 0.25);
}

a.btn.btn-primary-purplle-prod:hover {
  color: #ae5ea2 !important;
  background-color: white !important;
}

.modal-header .close {
  padding: 0rem 0rem;
}

.param {
  border: 1px solid #ced4da;
  padding: 10px 8px;
  color: #8b9094;
  width: 98%;
}

.parameters {
  display: -webkit-box;
}

.accordian-plus {
  color: white;
  background-color: #f05368;
  margin-top: 10px;
  margin-left: 13px;
  padding: 5px;
  border-radius: 50%;
}

.margin-rght {
  float: right;
  margin-right: 121px;
}

.accordian-minus {
  margin-top: 10px;
  margin-left: 13px;
  padding: 3px;
  border-radius: 50%;
  font-size: 22px;
}

.brder {
  border: 1px solid #b5acac;
  padding: 4px 14px;
  display: inline-block;
}

.mrgn-rgt {
  float: right;
  margin-right: 101px;
}

/* .panel-default > .panel-heading {
  padding: 0;
  border-radius: 0;
  color: #212121;
  border-color: #EEEEEE;
} */

.panel-title {
  font-size: 14px;
}

.more-less {
  float: right;
  color: #212121;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #eeeeee;
}

.demo {
  margin-left: -15px;
}

/* ----- v CAN BE DELETED v ----- */

.col-md-4.border-right.ngoPost-box {
  max-width: 100% !important;
}

a.img-view {
  position: absolute;
  margin-top: -30px;
  margin-left: -36px;
  color: blue;
}

.profile-sty {
  text-align: end;
}

.ngo-middle.container.pl-md-0.pr-md-4.mt-md-2 {
  max-width: 100% !important;
}

.max-h {
  max-height: 100px !important;
}

.wrap {
  float: left;
  margin: 0 20px 0 0;
}

.content {
  background-color: #444;
  width: 100px;
  height: 100px;
  position: absolute;
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  max-width: 100%;
}

i.fa.fa-calendar {
  position: absolute;
  top: 52px;
  right: 10%;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;

  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  max-width: 100%;
}

i.fa.fa-calendar.top {
  top: 37px !important;
}

i.fa.fa-clock-o {
  position: absolute;
  top: 51px;
  right: 9%;
}

/* i.fa.fa-clock-o {
  position: absolute;
  top: 55px;
  right: 7%;
} */

input.param-text {
  /* border: 1px solid; */
  /* border-radius: 7px; */
  border: 0.5px solid #000000;
  border-radius: 4px;
  width: 100%;
}

.background-none1 {
  background: none;
  border: none;
}

button.btn.filter-lead {
  border: 1px solid #bed867;
  background: #bed867;
  color: white;
  border-radius: 6px;
  padding: 1px 6px;
  font-size: 13px;
}

button.btn.clear-filter {
  color: white;
  background-color: #f05368;
  border: 1px solid #f05368;
  border-radius: 6px;
  padding: 1px 6px;
  font-size: 13px;
}

button.btn.filter-lead:hover {
  border: 1px solid #bed867;
  background: white;
  color: #bed867;
}

button.btn.clear-filter:hover {
  color: #f05368;
  background-color: white;
  border: 1px solid #f05368;
}

span.in-same-row {
  display: inline-flex;
}

i.fa.fa-toggle-off.font-sz {
  color: #f05368;
  /* background-color: green; */
}

i.fa.fa-toggle-on.font-sz {
  color: #f05368;
}

button.btn.btn-primary.btn-right.shadow1.filter {
  padding: 3px 31px;
  font-size: 15px;
}

i.fa.fa-search.padding {
  position: absolute;
  top: 49px;
  right: 9%;
}

i.fa.fa-calendar.updated-at {
  position: absolute;
  top: 55px;
  right: 4%;
}

a {
  cursor: pointer;
}

.Entries-pagination {
  font-weight: bolder;
}

a.show-page:hover {
  text-decoration: underline !important;
}

a.show-page:active {
  text-decoration: underline !important;
  border-bottom: #000 !important;
}

span.pagination-list {
  display: flex;
  /* align-content: flex-end; */
  justify-content: space-between;
}

.ModelInputlabel {
  font-size: 13px;
  font-weight: bolder;
}

.modalInputBox {
  font-size: 13px;
  font-weight: normal;
}

.textareaModal {
  font-size: 13px;
}

button.btn.filter-leadpopupbtn:hover {
  border: 1px solid #bed867;
  background: white;
  color: #bed867;
}

button.btn.filter-leadpopupbtn {
  border: 1px solid #bed867;
  background: #bed867;
  color: white;
  border-radius: 8px;
  padding: 3px 22px;
  font-size: 15px;
}

button.btn.clear-filter1 {
  color: white;
  background-color: #f05368;
  border: 1px solid #f05368;
  border-radius: 10px;
  padding: 3px 13px;
  font-size: 15px;
}

button.btn.clear-filter1:hover {
  color: #f05368;
  background-color: white;
  border: 1px solid #f05368;
}

button.btn.btn-primary.btn-right.shadow1.logevbtn {
  padding: 4px 16px;
  font-size: 12px;
}

button.btn.btn-primary.modal-sub-btn {
  /* padding: 4px 21px;
  background: #f05368;
  color: #fff !important;
  font-size: 14px;
  font-weight: 700;
  /* padding: 4px 12px; */
  /* border: 2px solid #f05368 !important;
  border-radius: 7px; */ 
      /* padding: 4px 21px; */
      background: #f05368;
      color: #fff !important;
      font-size: 13px;
      font-weight: 600;
      /* padding: 4px 12px; */
      border: 2px solid #f05368 !important;
      border-radius: 4px;
      width: 120px;
}

button.btn.btn-primary.modal-sub-btn:hover {
  /* padding: 4px 21px; */
  background: white !important;
  color: #f05368 !important;
  /* font-size: 14px;
  font-weight: 700; */
  /* padding: 4px 12px; */
  border: 2px solid #f05368 !important;
  /* border-radius: 7px;
} */
}

button.btn.btn-primary.modal-sub-btn1 {
  /* padding: 4px 37px; */
  background: #f05368;
  color: #fff !important;
  font-size: 13px;
  font-weight: 600;
  /* padding: 4px 12px; */
  border: 2px solid #f05368 !important;
  border-radius: 4px;
  width: 120px;
}
button.btn.btn-primary.modal-sub-btn-copy {
  padding: 1px 11px;
  background: #f05368;
  color: #fff !important;
  font-size: 13px;
  font-weight: 700;
  /* padding: 4px 12px; */
  border: 2px solid #f05368 !important;
  border-radius: 7px;
}
button.btn.btn-primary.modal-sub-btn-copy:hover{
  background: #fff !important;
  color:#f05368 !important;
}
button.btn.btn-primary.modal-sub-btn1:hover {
  
  background: white !important;
  color: #f05368 !important;
  
  /* padding: 4px 12px; */
  border: 2px solid #f05368 !important;
  
}


button.btn-primary-green.modal-sub-btn {
  /* padding: 4px 21px; */
      /* padding: 4px 21px; */
      border-radius: 4px;
      width: 120px;
      font-size: 13px;
      font-weight: 600;
}

input[type="checkbox"] {
  accent-color: #f05368;
}

.right-side-login {
  background-color: #f5f5f5;
}

/* .show-content {
  padding: 37% 25%;
} */
/* .container-fluid{
height: 100vh;
} */

.title>span {
  color: #fff;
  background-color: #f05368;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 5px;
  border-radius: 4px;
}

/* -------------------------------- */
.container.text-right.d-flex.mx-0.pt-4.align-items-center {
  display: flex;
  justify-content: space-evenly;
}

.container.text-right.d-flex.justify-content-end.mx-0.pt-0.align-items-center {
  position: absolute;
  top: 79%;
  /* bottom: 86%; */
  left: 0%;
}

.container.text-right.d-flex.mx-0.pt-4.align-items-center {
  position: relative;
  top: -2%;
  /* bottom: 86%; */
  left: -14%;
}

.wel-title {
  color: #F05368;
  font-weight: bold;
  position: absolute;
  top: 183%;
  left: 14%;
}

.wel-text {

  position: relative;
  left: 25%;
}



.right-side-login {
  background-color: #F5F5F5;
  height: 100vh;
  /* overflow:hidden; */
}

.left-side-login {

  height: 100vh;
}

.login-content {
  position: relative;
  top: 16%;
  left: 25%;
}

.login-head {
  text-align: center;
  position: relative;
  left: -25%;

}

.user-admin-login {
  display: inline-flex;
}

button.userbtn {
  background-color: white;
  border: white;
  padding: 7px
}

button.adminbtn {
  background-color: white;
  border: white;
  padding: 7px
}

button.userbtn:hover {
  background-color: #FACBD1;
  border: #FACBD1;
}

button.adminbtn:hover {
  background-color: #FACBD1;
  border: #FACBD1;
}

button.userbtn:active {
  background-color: #FACBD1;
  border: #FACBD1;
}

button.adminbtn:active {
  background-color: #FACBD1;
  border: #FACBD1;
}

.adminbtntext:hover {
  color: #F05368;
}

.userbtntext:hover {
  color: #F05368;
}

.adminbtntext1 {
  FONT-SIZE: 12PX;
}

.userbtntext1 {
  FONT-SIZE: 12PX;
}

h3.login-title {
  color: #F05368;
}

.row.header.border-bottom.dashboard-header {
  height: 92px;
}

.breadcrumbs.left-border-breadcrumbs.fixed-hed.leadlist-header {
  height: 92px;
  margin-left: -13px;
}

.row.header.report-header {
  height: 83px;
}

.row.header.border-bottom.drive-header {
  height: 92px;
}

span.Setting {

  /* position: relative;
  left: 34%; */
  position: absolute;
    left: 77%;
    top: 2%;
}

span.notification {
  /* position: relative;
  left: 42%; */
  /* position: relative;
  left: 23%; */
    /* position: absolute;
    left: 71%;
    top: 9%; */
    position: absolute;
    left: 71%;
}



h3.user-info {
  /* padding-left: 15%; */
  padding-left: 2%;
}

span.prof-img {
  position: relative;
  /* left: 13%; */
  left: 1%;
  top: 2%;
}

.row.border-bottom.pb-2.userlist-header {
  height: 92px;
  
}
.row.header.border-bottom.pb-3.pt-2.drive{
  height: 92px;
}


span.Setting {
  /* position: absolute;
  left: 68%;
  top: 1%; */

    position: absolute;
    left: 77%;
    top: 1%;
}


.dashboard-index {
  z-index: 5;
}

.responsive {
  width: 100%;
  max-width: 400px;
  height: auto;
  position: relative;

}

@media (max-width: 820px) or (max-width: 990px) {
  .responsive {
    display: none;
  }
}

img.img-prod {
  object-fit: contain;
}

.effect-underline {
  display: inline-block;
  position: relative;
}
.effect-underline-active{
  display: inline-block;
  position: relative;
 
  color: #F05368;
  border-bottom: 2px solid;
}

.effect-underline:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #F05368;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.effect-underline:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.showpwd {
  position: absolute;
  right: 6%;
  top: 38%;
  cursor: pointer;
  border: 1px solid #ced4da;
  padding: 9px;
  border-left: 0px;
  height: 38px;
  border-radius: 0.25rem;
}
/* .row.overflow-login {
  overflow: hidden;
} */
.show-hide-pwd{
  position: absolute;
  top: 46px;
  right: 27px;
  z-index: 1;
  cursor: pointer;
}
/* #new_Password:focus{
  .show-hide-pwd{
    display: block;
  }
}
#new_Password:focus>.show-hide-pwd {
  display: block;
} */
button.btn.modal-sub-btn.export-excel {
  /* position: relative;
  left: 439%;
  top: 2px; */
  /* height: 30px; */
  /* width: 20px; */
  /* padding: 1px 5px;
  font-size: 12px; */
  /* position: relative;
  left: 291%;
  top: 2px; */
  /* height: 30px; */
  /* width: 20px; */
  /* padding: 6px 5px;
  font-size: 13px;
  width: 130px;
  font-weight: 600; */
  position: relative;
    /* left: 241%; */
    left: 1401%;
    top: 0px;
    /* height: 30px; */
    /* width: 20px; */
    padding: 6px 5px;
    font-size: 20px;
    /* width: 130px; */
    font-weight: 600;
    color: red;
}
.col-md-4.ngoPost-box {
  max-width: 100% !important;
}
.disp_editDel_btn {
  display: inline-flex;
  justify-content: center;
}
button.btn.deactive-btn {
  color: #fff;
  background-color: #B0BFB5;
  border: 1px solid #B0BFB5;
  /* width: 120px; */
  width: 105px;
  /* font-size: 13px; */
  font-size: 12px;
  font-weight: 600;
}
.deactive-text {
  color: #B0BFB5;
 
}
button.btn.apply-btn{
  color: #fff;
    /* background-color: #B0BFB5; */
    
      background: linear-gradient(
          to right,
          #cf1f1f  20%,
          #fa6100  30%,
          #0190cd 70%,
          #764ada 80%
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
      background-size: 500% auto;
      animation: textShine 5s ease-in-out infinite alternate;

    border: 1px solid #B0BFB5;
    /* width: 120px; */
    width: 64px;
   
    font-size: 11px;
    font-weight: 600;
}
@keyframes textShine {
  0% {
      background-position: 0% 50%;
  }
  100% {
      background-position: 100% 50%;
  }
}
button.btn.active-btn {
  /* color: #fff;
  background-color: #2DD784;
  border: 1px solid #2DD784;
  padding-left: 20px;
  padding-right: 20px; */
  color: #fff;
    background-color: #2DD784;
    border: 1px solid #2DD784;
    /* padding-left: 20px; */
    /* padding-right: 20px; */
    /* width: 120px; */
    width: 105px;
    /* font-size: 13px; */
    font-size: 12px;
    font-weight: 600;
}
.active-text {
  color: #2DD784;
 
}
.applied-text {
  color: #ff944d;
 
}
button.btn.applied-btn {
  /* color: #fff;
  background-color: #2DD784;
  border: 1px solid #2DD784;
  padding-left: 20px;
  padding-right: 20px; */
  color: #fff;
    background-color: #ff944d;
    border: 1px solid #ff944d;
    /* padding-left: 20px; */
    /* padding-right: 20px; */
    /* width: 120px; */
    width: 105px;
    font-size: 12px;
    font-weight: 600;
}

.ivr-link-btn{
  font-size: 15PX;
  padding-left: 5PX;
  padding-right: 5PX;
}
.card.dash-card {
  /* height: 100px; */
  height: 81px;
  background-color: #fff;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgb(210 205 205 / 30%);
    margin-top: 22px;
}
button.btn.btn-right.Add-appoint-btn{
  /* padding: 4px 21px; */ 
    background: #f05368;
    color: #fff !important;
    font-size: 13px;
    font-weight: 600;
    /* padding: 4px 12px; */
    border: 2px solid #f05368 !important;
    border-radius: 4px;
    width: 205px;
}
button.btn.btn-right.Add-appoint-btn:hover{
  /* padding: 4px 21px; */ 
    background: #fff !important;
    color: #f05368 !important;
    
    border: 2px solid #f05368 !important;
  
}
.titleenq{
  color: #f05368;
    display: flex;
    justify-content: center;
}
.card.d-flex.justify-content-center.container.enq-card {
  /* background-color: #f7f7f7; */
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  border-radius: 2px;
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
  border: none;
}
.display-center {
  display: flex;
  justify-content: center !important;
}

.ok-btn{
  padding: 4px 22px;

}
i.fa.fa-calendar.start-date{
  position: absolute;
    top: 50px;
    right: 16%;
    z-index: 1;

}
.breadcrumbs.border-right-0.dashboard-index {
  /* width: 70%; */
  width: 80%;
}
button.btn.btn-primary.modal-sub-btn.w-100.lead-btn {
  /* height: 30px; */
  /* width: 20px; */
  /* position: relative;
  left: 259%;
  top: 2px; */
  /* height: 30px; */
  /* width: 20px; */
  /* padding: 6px 5px; */
  /* padding: 6px 23px;
  font-size: 13px;
  width: 130;
  font-weight: 600; */
    
    position: relative;
    left: 340%;
    top: 7px;
 
    padding: 2px 7px;
    font-weight: 600;
    font-size: 13px;
}
.lfc-table.y-scroll {
  overflow-x: hidden !important;
}
.modal-dialog.lfc-main.status-modal-height {
  height: 40% !important;
  overflow: auto;
}
/* .lfc-table.y-scroll ::-webkit-scrollbar {
  width: 1px;
  border-radius: 10px; */
/* } */
li.feature-list {
  display: inline-flex;
  margin-top: 15px;
  list-style: none;
  margin-left: 21px;
}
.wel-text1{
  display: none;
}
@media (max-width: 600px){
  .container-fluid.height{
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  height: 100vh;
}
/* .wel-text {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
} */
}
@media (min-width: 600px){
  .container-fluid.height{
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    height: 100vh;
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px)  {
  .wel-text {
    position: relative;
    left: 76%;
}
.wel-title {
  color: #F05368;
  font-weight: bold;
  position: absolute;
  /* top: 236%; */
  top: 236%;
  left: 14%;
}
}
@media only screen and (max-width: 991px) and (min-width: 900px){
  .wel-text{
    display: none;
  }
  .wel-text1 {
    position: relative;
    left: 25%;
    display:inline;
}
}
@media only screen and (max-width: 900px) and (min-width: 768px){
  .wel-text{
    display: none;
  }
  .wel-text1 {
    position: relative;
    left: 16%;
    display:inline;
}
}
@media only screen and (max-width:1199px ) and (min-width: 992px){
  .add-btn-laptop{
    display: flex;
    justify-content: space-around !important;
  }
  
}
@media only screen and (max-width: 1199px) and (min-width: 768px){
  .row.show-list-tab {
    padding-top: 34px;
}
.report-lap{
  padding-top: 30px !important;
}
}

@media only screen and (max-width: 768px) and (min-width: 600px){
  .wel-text {
    position: relative;
    left: 55%;
}

.middle-col .input-group {
  display: inline-flex;
  /* width: 107px; */
  width: 186px;
  margin-top: 3px;
}

.btn-primary-cart-new{
  /* width: 55px !important; */
  width: 90px !important;
  margin-top: 6px !important;
  padding: 3px !important;
  font-size: 11px !important;
}
.form-control {
  font-size: 12px;
}

}

@media only screen and (max-width: 474px)  {
  .btn-primary-cart-new{
    width: 55px !important;
   
    margin-top: 6px !important;
    padding: 3px !important;
    font-size: 11px !important;
  }
   .middle-col .input-group {
    display: inline-flex;
    width: 107px;
    /* width: 152px; */
    margin-top: 3px;
  }
}


@media only screen and (max-width: 991px)  {
  .row.wel-img{
    display: none;
  }
}

@media only screen and (max-width: 1199px)  {
  .breadcrumbs.border-right-0.dashboard-index {
    width: 100%;
}
.content-padding-top {
  margin-top: 10%;
}
}
@media only screen and (max-width: 599)  {
  .mob-padding-left-renew.pt3{
    padding-left: 340px;
  }
  
}
/* .breadcrumbs.border-right-0.dashboard-index {
  height: 137px !important;
} */
.note-card{
  background-color: #f7f7f7;
  /* padding: 20px 25px 30px; */
  padding: 11px 9px 9px;
  margin: 0 auto 25px;
  /* margin-top: 50px; */
  border-radius: 5px;
}
.reportsubmenu{
  /* padding-left: 7%;
 
  transition: 1s ease-in-out; */
  font-size: 14px;
  padding: 9px 10px 9px 44px !important;
  position: relative;
}
#minusreport{
  display: none;
}
.openmenu{
  display: block;
}
.closemenu{
  display: none;
}
.mastersubmenu{
 
  font-size: 14px;
  padding: 9px 10px 9px 44px !important;
  position: relative;
}
/* -----------------------filterdropdown------------------------------------------------- */
.filterdropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  cursor: pointer !important;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.filterdropdown-toggle{
  font-size: 13px !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}
.filterdropdown-toggle:focus{
  /* font-size: 13px !important; */
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%)!important;;
}
.middle-col .filterdropdown {
  /* position: absolute!important; */
  position: relative;
  font-size: 12px !important;
  cursor: pointer !important;
  /* left: 62px!important; */
  color: black !important;
  /* margin-top: -30px; */
  z-index: 1;
}
.filterdropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  transform-origin: left top 0;
  box-shadow: inherit;
  background-color: #fff;
  z-index: 1000;
  float: left;
  margin: 0.125rem 0 0;
  color: #212529;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
} 
 .filterdropdown-menu {
  padding: 0;
  font-size: 14px;
}
.filterdropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  /* display: none; */
  font-size: 12px !important;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 0.25rem;
}
 .filterdropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
} 

.custdate{
  /* width: 108px;
  height: 19px;
  border-radius: 4px; */
  padding: 10px;
  width: 257px;
  border-radius: 5px;
  font-size: 10px;
  border: 1px solid black;
}
.filterlist:hover{
 background-color: #f6c3ca ;
 color: white !important;
}
.filterlist.filterlistActive{
  background-color: #f05368 ;
 color: white !important;
}
button.btn.applydt {
  font-size: 12px;
  background: #f05368;
  color: white;
  border: 1px solid #f05368;
  padding: 3px 6px;
  font-weight: 600;
}
button.btn.applydt:hover{
  background:white;
  color: #f05368;
}
button.btn.canceldt {
  font-size: 12px;
  background: #aece41;
  color: white;
  border: 1px solid #aece41;
  padding: 3px 4px;
  font-weight: 600;
}
button.btn.canceldt:hover{
  background:white;
  color: #aece41;
}

.date-pos1 {
  padding-bottom: 11px;
  padding-top: 13px;
  width: 125px;
  font-size: 12px;
  border-radius: 5px;
  text-align: center;
  position: relative;
  /* color: #ababab; */
  display: grid;
  z-index: 1;
}

/* ----------------------------------------------------------------------------------- */
.sub-form{
  font-size: 12px;
  background: #f05368;
  color: white;
  border: 2px solid #f05368;
  padding: 3px 6px;
  font-weight: 600;
  width: 120px;
}
.sub-form:hover{
  background:white !important;
  color: #f05368 !important;
}
.reshedule{
  font-size: 12px;
  background: #aece41;
  color: white !important;
  border: 2px solid #aece41;
  padding: 3px 6px;
  font-weight: 600;
  width: 120px;
}
.reshedule:hover{
  background:white !important;
  color: #aece41 !important;
}
svg.dropdown-heading-dropdown-arrow.gray {
  width: 29px !important;
  position: relative;
  top: 17%;
}
svg.dropdown-search-clear-icon.gray {
  width: 33px;
  height: 27px;
  top: 6px;
  right: 24px;
  position: absolute;
}
.dropdown-container {
  height: 37px;
}
.css-1xc3v61-indicatorContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  color: hsl(0, 0%, 80%);
  padding: 0px;
  box-sizing: border-box;
  width: 40px;
}
svg.css-tj5bde-Svg {
  position: relative;
  right: -1px;
  width: 30px;
  height: 16px;
  top: 3px;
 
}
.cdl {
  width: 124px !important;
}
input#search_input {
  border: none !important;
}
.highlightOption {
  background: #f05368 !important;
  color: #fff;
}
.multiSelectContainer li:hover {
  background: #f05368 !important;
  color: #fff;
  cursor: pointer;
}
.chip {
  padding: 4px 10px;
  background: #f05368 !important;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 11px;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  line-height: 19px;
  color: #fff;
}

/* .multiselect-container {
  max-height: 200px !important; 
  overflow-y: auto !important;
} */
@media(min-width: 1200px){
  .offset-xl-3{
    margin-left: 20% !important;
  }
  .side-menu {
    /* width: 303px; */
    width: 230px;
    max-width: 100%;
}
}
.setting-margin{
  margin: 0 6px;
}
/* .close-icon {
  display: none !important;
} */
/*---------------- Notifiacation for todays oppointment------------------ */
.appoint-noti-padding{
  padding: 20px 40px;
}
/* ------------------------------------------------------------------------------- */

/* ------------------------lead sticky header of cards---------------------------- */

.lead-page-head{
  position: fixed;
    top: 0px;
    /* z-index: 1; */
    z-index: 2;
    background: white;
}
/* ------------------------------------------------------------------------------- */

.renew-gradient{
  background: linear-gradient(270deg, #f94907, #f9a907, #f9de07);
  background-size: 700% 600%;
   border: none !important;
   width: 120px;
   color:white;

   -webkit-animation:textbg 1s ease infinite;
   -moz-animation: textbg 1s ease infinite;
   animation: textbg 1s ease infinite;
  /* -moz-animation:textbg 1s ease infinite; */
  /* animation:textbg 1s ease infinite; */
}
.renew-gradient:hover{
  color:white !important;
}
@-webkit-keyframes textbg{
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes textbg{
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes textbg{
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
.btn.clear-btn.btn-padding {
  /* border-radius: 7px;
  background: #aece41;
  border-color: #aece41;
  color: white; */
  border-radius: 4px;
  background: #aece41;
  border: 2px #aece41 solid;
  color: white;
  font-size: 13px;
  font-weight: 600;
  width: 120px;
}

.btn.clear-btn.btn-padding:hover {
  border-radius: 4px;
  background: white;
  border: 2px #aece41 solid;
  color: #aece41;
}
a.btn.clear-btn1.btn-padding {
  font-size: 13px;
    font-weight: 600;
    width: 120px;
  border-radius: 4px;
  background: #f05368;
  border: 2px #f05368 solid;
  color: white;
}
a.btn.clear-btn1.btn-padding:hover{
 
  border-radius: 4px;
  background: white;
  border: 2px #f05368 solid;
  color: #f05368;
}
.hidden-column {
  display: none;
}
i.fa.fa-clock-o.slot_info {
  position: absolute;
  top: 12px;
  right: 14%;
 /* z-index: 1; */
}
.card.superaddash-card{
      /* height: 100px; */
      height: 100px;
      background-color: #fff;
      padding: 10px 17px 10px;
      margin: 0 auto 25px;
      margin-top: 50px;
      border-radius: 10px;
      box-shadow: 0px 2px 2px rgb(210 205 205 / 30%);
      margin-top: 22px;
}
.col-6.text-left {
  font-size: 11PX;
}