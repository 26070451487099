label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.modalInputBox.form-control {
  position: relative;
  z-index: 1;
  background-color: #fff;
}

.isTimeValid {
  font-size: 16px;
  color: #f05368;
  font-weight: 500;
}

@-moz-document url-prefix() {
  .modalInputBox.form-control {
    background-color: #fff;
  }
}

.modal-dialog.lfc-main {
  height: 100%;
  overflow: auto;
}

.modal-dialog.lfc-main .modal-content {
  height: 100%;
  overflow: auto;
}

.modal-dialog.lfc-main .modal-content .lfc-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal-dialog.lfc-main .modal-content .lfc-container .lfc-table {
  flex: 1;
  overflow: auto;
}
.modal-dialog.lfc-main .modal-content .lfc-container h3.lfc-title {
  margin: 0;
  padding: 10px 15px;
}

.notification-wrap .notification-poper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 0 14px #ddd;
  border-radius: 4px;
  transform: translateY(-30px);
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}

.notification-wrap .notification-poper.open {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.notification-wrap .notification-poper .notification-box {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 228px;
}

.notification-wrap .notification-poper .notification-box .nb-title {
  text-align: left;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
  color: #575757;
}

.notification-wrap .notification-poper .notification-box .nb-items {
  flex: 1;
  overflow: auto;
}

.notification-wrap .notification-poper .notification-box .nb-items .nb-item {
  display: flex;
  align-items: center;
  padding: 3px 0;
  border-bottom: 1px solid #ddd;
}

.notification-wrap .notification-poper .notification-box .nb-items .nb-item a {
  flex: 1 1;
  text-align: left;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 500;
  padding: 0 10px;
  margin: 0;
  color: #000;
  text-decoration: none;
}

.notification-wrap
  .notification-poper
  .notification-box
  .nb-items
  .nb-item
  a:hover {
  color: #f05368;
  transition: all ease 0.3s;
}

.notification-wrap
  .notification-poper
  .notification-box
  .nb-items
  .nb-item
  button {
  padding: 0;
  background-color: unset;
  border: 0;
  line-height: normal;
  display: flex;
  font-size: 14px;
  color: #383737;
  cursor: pointer;
}

#total-lead-modal {
  height: calc(100% - 3.5rem);
}

/*  */

.Toastify svg :not(svg) {
  transform: unset !important;
}

.notification-toast-wrap {
  text-align: center;
  padding: 15px 20px;
}

.notification-toast-wrap .nt-time {
  font-size: 12px;
  color: #ddd;
  font-weight: 600;
}

.notification-toast-wrap .nt-icon {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.notification-toast-wrap .nt-icon .nti-inner {
  padding: 15px;
  border-radius: 4px;
  background-color: #3d3d3d;
}

.notification-toast-wrap .nt-icon .nti-inner svg {
  font-size: 30px;
}

.notification-toast-wrap .nt-title {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
}

.notification-toast-wrap .nt-body {
  font-size: 13px;
  color: #dedede;
  letter-spacing: 0.5px;
  padding: 0 20px;
}

.Toastify__toast-container--top-right button.Toastify__close-button {
  display: flex;
  font-size: 16px;
  position: absolute;
  right: 10px;
  z-index: 9;
  top: 10px;
}

.Toastify__toast.Toastify__toast--dark {
  border-radius: 5px;
}
.text-primary {
  color: #f05368 !important;
}